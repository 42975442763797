import CustomForm from "../components/CustomForm";
import { useState, useRef } from "react";
import React from "react";
import Table from "../components/Table";
import appvariables from "../appvariables";
import { usHttp, usHttp2 } from "../custom-hooks/use-http";
import Button from "../components/Button";
import { useRouteLoaderData } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const AddProduct = (props) => {

    const [name, setName] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const [minquantity, setMinquantity] = useState(0);
    const [nameerror, setNameerror] = useState('');
    const [packageerror, setPackageerror] = useState('');
    const [companyerror, setCompanyerror] = useState('');
    const nameref = useRef();
    const packageeref = useRef();
    const companyref = useRef();
    const minquantityref = useRef();

    const masterdispatcher = useDispatch();

    const { companies } = useRouteLoaderData('productlayoutloader');

    const addproducttoserver = (e) => {
        var requestbody = {}
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        var productdata = {
            'name': name,
            'package': packagee.toLowerCase(),
            'company': company,
            'minquantity': String(minquantity),
        }
        requestbody['product'] = productdata
        usHttp2(appvariables.apiurl+'product/addone/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => {
            masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
        setName('');
        setMinquantity(0);
        setPackagee('');
        setCompany('');
        nameref.current.focus();
        setNameerror('');
        setPackageerror('');
        setCompanyerror('');
        nameref.current.value = ''
        packageeref.current.value = ''
        companyref.current.value = ''
        minquantityref.current.value = 0
    }

    const nameinputblur = () => {
        if(nameref.current.value.trim().length < 3)
        {
            setNameerror('Name should be minimum 3 letters');
            nameref.current.value = name;
        }
        else
        {
            setNameerror('');
        }
    }

    const packageinputblur = () => {
        if(packageeref.current.value.trim().length < 3)
        {
            setPackageerror('Package should be minimum 3 letters');
            packageeref.current.value = packagee;
        }
        else
        {
            setPackageerror('');
        }
    }

    const companyinputblur = () => {
        if(companyref.current.value.trim().length < 4)
        {
            setCompanyerror('Company should be minimum 4 letters');
            companyref.current.value = company;
        }
        else
        {
            setCompanyerror('');
        }
    }

    const nameinputchange = () => {
        setName(nameref.current.value.trim());
    }

    const packageinputchange = () => {
        setPackagee(packageeref.current.value.trim());
    }

    const companyinputchange = () => {
        setCompany(companyref.current.value.trim());
    }

    const minquantityinputchange = () => {
        setMinquantity(Math.max(0, minquantityref.current.value));
        minquantityref.current.value = Math.max(0, minquantityref.current.value);
    }
    
    const inputs = [
            {
                id: 'addproductname',
                displayname: 'Name',
                name:'name',
                type: 'text',
                refvar: nameref,
                changehandler: nameinputchange,
                error: nameerror,
                blurhandler: nameinputblur,
            },
            {
                id: 'addproductpackage',
                displayname: 'Package',
                name:'package',
                type: 'text',
                refvar: packageeref,
                changehandler: packageinputchange,
                error: packageerror,
                blurhandler: packageinputblur,
            },
            {
                id: 'addproductcompany',
                displayname: 'Company',
                name: 'company',
                type: 'text',
                refvar: companyref,
                changehandler: companyinputchange,
                datalist: companies,
                error: companyerror,
                blurhandler: companyinputblur,
            },
            {
                id: 'addproductminquantity',
                displayname: 'Minimum Quantity',
                name:'minquantity',
                type: 'number',
                refvar: minquantityref,
                changehandler: minquantityinputchange
            }
        ]

        const submitDisabled = () => {
            return name.length < 3 || packagee.length < 3 || company.length < 4
        }



    return(
        <div>
            <CustomForm resetdisabled={true} submitdisabled={submitDisabled()} inputs={inputs} inputboxstyle={'normal'} submitHandler={addproducttoserver}/>
        </div>
    );

}

const Addproducts = (props) => {

    const [name, setName] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const [minquantity, setMinquantity] = useState(0);
    const [nameerror, setNameerror] = useState('');
    const [packageerror, setPackageerror] = useState('');
    const [companyerror, setCompanyerror] = useState('');
    const [products, setProducts] = useState([]);
    const nameref = useRef();
    const packageeref = useRef();
    const companyref = useRef();
    const minquantityref = useRef();

    const masterdispatcher = useDispatch();

    const { companies } = useRouteLoaderData('productlayoutloader');

    const addproductstoserver = (e) => {
        var requestbody = {};
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        requestbody['products'] = products;
        usHttp2(appvariables.apiurl+'product/addmore/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => {
            masterdispatcher(notificationsliceactions.updatenotification({'message': 'Successfully Added Product', 'status': 'Success'}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
        setProducts([]);
    }

    const addproducttolocal = (e) => {
        e.preventDefault();
        let productdata = {
            'name': name,
            'package': packagee.toLowerCase(),
            'company': company,
            'minquantity': String(minquantity),
            'rowkey': uuidv4()
        }
        let temp_products = [...products];
        temp_products.push(productdata);
        setProducts(temp_products);
        setName('');
        setMinquantity(0);
        setPackagee('');
        setCompany('');
        nameref.current.focus();
        setNameerror('');
        setPackageerror('');
        setCompanyerror('');
        nameref.current.value = ''
        packageeref.current.value = ''
        companyref.current.value = ''
        minquantityref.current.value = ''
    }

    const nameinputblur = () => {
        if(nameref.current.value.trim().length < 3)
        {
            setNameerror('Name should be minimum 3 letters');
            nameref.current.value = name;
        }
        else
        {
            setNameerror('');
        }
    }

    const packageinputblur = () => {
        if(packageeref.current.value.trim().length < 3)
        {
            setPackageerror('Package should be minimum 3 letters');
            packageeref.current.value = packagee;
        }
        else
        {
            setPackageerror('');
        }
    }

    const companyinputblur = () => {
        if(companyref.current.value.trim().length < 4)
        {
            setCompanyerror('Company should be minimum 4 letters');
            companyref.current.value = company
        }
        else
        {
            setCompanyerror('');
        }
    }

    const nameinputchange = () => {
        setName(nameref.current.value.trim());
    }

    const packageinputchange = () => {
        setPackagee(packageeref.current.value.trim());
    }

    const companyinputchange = () => {
        setCompany(companyref.current.value.trim())
    }

    const minquantityinputchange = () => {
        setMinquantity(Math.max(0, minquantityref.current.value));
        minquantityref.current.value = Math.max(0, minquantityref.current.value);
    }
    
    const inputs = [
        {
            id: 'addproductname',
            displayname: 'Name',
            name:'name',
            type: 'text',
            refvar: nameref,
            changehandler: nameinputchange,
            error: nameerror,
            blurhandler: nameinputblur,
        },
        {
            id: 'addproductpackage',
            displayname: 'Package',
            name:'package',
            type: 'text',
            refvar: packageeref,
            changehandler: packageinputchange,
            error: packageerror,
            blurhandler: packageinputblur,
        },
        {
            id: 'addproductcompany',
            displayname: 'Company',
            name: 'company',
            type: 'text',
            refvar: companyref,
            changehandler: companyinputchange,
            datalist: companies,
            error: companyerror,
            blurhandler: companyinputblur,
        },
        {
            id: 'addproductminquantity',
            displayname: 'Minimum Quantity',
            name:'minquantity',
            type: 'number',
            refvar: minquantityref,
            changehandler: minquantityinputchange
        }
    ]

    const deleteRow = (rowkeyy) => {
        var temp_rows = [...products];
        for(var i=0; i < temp_rows.length; i++)
        {
            if(temp_rows[i].rowkey === rowkeyy)
            {
                temp_rows.splice(i, 1);
                break;
            }
        }
        setProducts(temp_rows);
    }

        const tablefields = [
            {'name':'Name', 'key':'name'},
            {'name':'Package', 'key':'package'},
            {'name':'Company', 'key':'company'},
            {'name':'Minimum Quantity', 'key':'minquantity'},
            {'name': 'Delete', 'key': 'rowkey', transformerr: deleteRow, text: 'X', cs: { width: "70px" }},
        ]

        const submitDisabled = () => {
            return name.length < 3 || packagee.length < 3 || company.length < 4
        }


    return(
        <div  style={{display: 'flex', flexDirection:'column' ,alignItems: 'center', width: '100%'}}>
            <CustomForm resetdisabled={true} submitdisabled={submitDisabled()} inputs={inputs} inputboxstyle={'normal'} submitHandler={addproducttolocal} />
            <Table data={products} tablefields={tablefields} />
            <Button disabled={products.length < 1} cs={{backgroundColor: products.length < 1 && 'rgba(0,0,0,0.6)', borderColor: products.length < 1 && 'rgba(0,0,0,0.6)'}} clickhandler={addproductstoserver}>Add Products</Button>
        </div>
    );
} 

export {Addproducts, AddProduct};