import { useEffect, useState } from "react";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Details from "../../components/Details";
import Table from "../../components/Table";

const AddStocktobill = (props) => {

    const [tabledata, setTabledata] = useState(props.tabledata);
    const [filteredtabledata, setFilteredtabledata] = useState(props.tabledata);

    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const [tablefields, setTablefields] = useState([
        {'name': 'Name', 'key': 'Name', 'filter': 1},
        {'name': 'Package', 'key': 'Package', 'filter': 1},
        {'name': 'Company', 'key': 'Company', 'filter': 1},
        {'name': 'Batch Id', 'key':'BatchId', 'filter': 1},
        {'name': 'Price', 'key': 'Price'},
        {'name': 'Expiry Date', 'key':'ExpiryDate', 'filter': 1, transformer: TranformDate}
    ]);

    useEffect(() => {
        if(filteredtabledata.length === 1)
        {
            setTablefields([
                {'name': 'Name', 'key': 'Name', 'filter': 1},
                {'name': 'Package', 'key': 'Package', 'filter': 1},
                {'name': 'Company', 'key': 'Company', 'filter': 1},
                {'name': 'Batch Id', 'key':'BatchId', 'filter': 1},
                {'name': 'Price', 'key': 'Price'},
                {'name': 'Expiry Date', 'key':'ExpiryDate', 'filter': 1, transformer: TranformDate},
                {'name': 'Action', 'key': 'string', 'transformerr': props.selectStock, text: 'Add'}
            ]);
        }
        else
        {
            setTablefields([
                {'name': 'Name', 'key': 'Name', 'filter': 1},
                {'name': 'Package', 'key': 'Package', 'filter': 1},
                {'name': 'Company', 'key': 'Company', 'filter': 1},
                {'name': 'Batch Id', 'key':'BatchId', 'filter': 1},
                {'name': 'Price', 'key': 'Price'},
                {'name': 'Expiry Date', 'key':'ExpiryDate', 'filter': 1, transformer: TranformDate}
            ])
        }
    },[filteredtabledata]);

    const filterchangehandler = (newarray) => {
        setFilteredtabledata(newarray)
    }

    return(
        <div style={{height: '750px'}} >
            <Table data={tabledata} filterchangehandler={filterchangehandler} tablefields={tablefields} limit={true} cs={{height: '249px', overflow: 'hidden', marginBottom: '10px'}} />
            <Button cs={{ position: 'absolute',  right: '40px' }} clickhandler={props.bclickhandler} >Cancel</Button>
            {props.stock !== '' && 
            <>
                <Details data={props.billrowdetails}/>
                <CustomForm resetdisabled={true} submitdisabled={props.submitdisabled} inputs={props.cfinputs} inputboxstyle={'equal'} submitHandler={props.cfsubmithandler}/>
            </>}
        </div>
    );
}

export default AddStocktobill;