import { React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadingsliceactions } from '../store/loading-slice';
import { usHttp2 } from '../custom-hooks/use-http';
import appvariables from '../appvariables';
import Button from '../components/Button';
import Table from '../components/Table';

const Errors = () => {
    const [stockerrors, setStockerrors] = useState([]);
    const masterdispatcher = useDispatch();

    const fetchStockErrors = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));

        usHttp2(appvariables.apiurl+'stock/errors/', 'POST', {})
        .then((response) => response.json())
        .then((json) => 
        {
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
            if(json.status === "Success")
            {
                setStockerrors(json.stocks);
            }
        })
    }

    const AddRate = (id, rate) => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        usHttp2(appvariables.apiurl + 'stock/addrate/', 'POST',{id: id, rate: rate})
        .then((response) => response.json())
        .then((json) => {
          if(json['status'] == 'Success')
          {
            let kkk = [...stockerrors];
            for(let i=0; i<stockerrors.length; i++)
            {
              if(stockerrors[i].id == id)
              {
                kkk.splice(i, 1);
                break;
              }
            }
            setStockerrors(kkk);
          }
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
        })
    }

    const createButtons = (mrates) => {
        return (
            <>
            {mrates.map((mrate, index) => {
                return <Button cs={{padding: '4px 8px'}} clickhandler={() => {
                    AddRate(mrate.id, mrate.rate);
                }} >{mrate.rate}</Button>
            } )}
            </>
        )
    }

    const tablefields = [
        {'name': 'Id', 'key': 'id', cs: {width: '10px'}},
        {'name': 'Name', 'key': 'name'},
        {'name': 'Package', 'key': 'package'},
        {'name': 'Company', 'key': 'company'},
        {'name': 'Price', 'key': 'price'},
        {'name': 'Details', 'key': 'details'},
        {'name': 'Matched Rates', 'key': 'rates', transformer: createButtons }
    ]

    return(
        <>
            <h2>Errors</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <h3>Stock Errors</h3>
                <Button cs={{ cursor: 'pointer'}} clickhandler={fetchStockErrors} >Get Stock Errors</Button>
            </div>
            <Table data={stockerrors} tablefields={tablefields} />
        </>
    )
}

export default Errors;