import { createSlice } from "@reduxjs/toolkit";
import { usHttp } from "../custom-hooks/use-http";
import appvariables from "../appvariables";

const initvalue  = {
    'cca':'',
    'name': 'Guest',
    'phonenumber':'na',
    'navbarlinks': [{'home':'Home'},{'shopbilling/login': 'Login'}],
    'sectionlinks': {},
    'navbarhide': false
}

const budataSlice = createSlice({
    name: 'billinguserdata',
    initialState: initvalue,
    reducers: {
        updatebudatastate(state, action)
        {
            state.name = action.payload.name;
            state.phonenumber = action.payload.phonenumber;
            state.navbarlinks = action.payload.navbarlinks;
            state.sectionlinks = action.payload.sectionlinks;
            state.permissions = action.payload.permissions;
        },
        updatebucca(state, action)
        {
            state.cca = action.payload;
            state.name = initvalue.name;
            state.phonenumber = initvalue.phonenumber;
            state.navbarlinks = initvalue.navbarlinks;
            state.sectionlinks = initvalue.sectionlinks;
        },
        updatenavbarhide(state, action)
        {
            state.navbarhide = action.payload;
        }
    }
});

export default budataSlice.reducer;

export const budatasliceactions = budataSlice.actions;