import { useRef, useState } from "react";
import { usHttp, usHttp2 } from "../custom-hooks/use-http";
import CustomForm from "../components/CustomForm";
import appvariables from "../appvariables";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";

const Addcustomer = () => {
    const [name, setName] = useState('');
    const [village, setVillage] = useState('');
    const [phonenumber, setPhonenumber] = useState(0);
    const [nameerror, setNameerror] = useState('');
    const [villageerror, setVillageerror] = useState('');
    const [phonenumbererror, setPhonenumbererror] = useState('');
    const nameref = useRef();
    const villageref = useRef();
    const phonenumberref = useRef();
    const { villages } = useLoaderData();

    const masterdispatcher = useDispatch();

    const addcustomerfunction = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        var requestbody = {
            'name': name,
            'village': village,
            'phonenumber': phonenumber,
        }
         usHttp2(appvariables.apiurl+'customer/add/', 'POST', requestbody)
         .then((response) => response.json())
         .then((json) => {
            masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
         })
        setName('');
        setPhonenumber(0);
        setVillage('');
        nameref.current.value = ''
        villageref.current.value = ''
        phonenumberref.current.value = ''
    }

    const nameinputblur = () => {
        if(name.length < 6)
        {
            setNameerror('Name Should be atleast 6 letters');
            nameref.current.value = name;
        }
        else
        {
            setNameerror('');
        }
    }

    const villageinputblur = () => {
        if(village.length < 6)
        {
            setVillageerror('Village Should be atleast 6 letters');
            villageref.current.value = village;
        }
        else
        {
            setVillageerror('');
        }
    }

    const phonenumberinputblur = () => {
        if(phonenumber.toString().length !== 10 || phonenumber > 9999999999 || phonenumber < 6000000000)
        {
            setPhonenumbererror('Enter valid Phone Number');
        }
        else
        {
            setPhonenumbererror('');
        }
    }

    const nameinputchange = () => {
        setName(nameref.current.value.trim());
    }

    const villageinputchange = () => {
        setVillage(villageref.current.value.trim());
    }

    const phonenumberinputchange = () => {
        setPhonenumber(phonenumberref.current.value.trim());
    }

    const inputs = [
            {
                id: 'name',
                displayname: 'Name',
                name:'name',
                type: 'text',
                refvar: nameref,
                changehandler: nameinputchange,
                error: nameerror,
                blurhandler: nameinputblur,
            },
            {
                id:'village',
                displayname: 'Village',
                name:'village',
                type: 'text',
                refvar: villageref,
                changehandler: villageinputchange,
                datalist: villages,
                error: villageerror,
                blurhandler: villageinputblur,
            },
            {
                id: 'pn',
                displayname: 'Phone Number',
                name:'phonenumber',
                type: 'number',
                refvar: phonenumberref,
                changehandler: phonenumberinputchange,
                error: phonenumbererror,
                blurhandler: phonenumberinputblur,
                min: 6000000000,
                max: 9999999999,
            }
        ]

        const submitDisabled = () => {
            return name.length < 6 || village.length < 6 || phonenumber.toString().length !== 10 || phonenumber > 9999999999 || phonenumber < 6000000000;
        }

    return(
        <div>
            <CustomForm resetdisabled={true} submitdisabled={submitDisabled()} inputs={inputs} inputboxstyle={'normal'} submitHandler={addcustomerfunction}/>
        </div>
    );
}

export default Addcustomer;