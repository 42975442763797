import { Link } from "react-router-dom";
import classes from './homepage.module.css';
import { usHttp } from "../../custom-hooks/use-http";
import appvariables from "../../appvariables";
import { useSelector } from "react-redux";

const Customerhomepage = (props) => {
    var customersectionlinks = useSelector(state => state.budata.sectionlinks.customer);

    if(customersectionlinks === undefined)
    {
        customersectionlinks = []
    }

    return(
        <div className={classes.homepage}>
            {customersectionlinks.map((customersectionlink, index)=> 
                <Link
                 key={'kkl'+index}
                 to={Object.keys(customersectionlink)[0]}>
                    {Object.values(customersectionlink)[0]}
                 </Link>
            )}
        </div>
    )
}


const loadAllCustomersData = async () => {
    const responsedata = await usHttp(appvariables.apiurl + 'customer/getall/', 'POST', {});
    if(responsedata != null)
    {
        return {
            'customers': responsedata['customers'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
}

const loadVillageNames = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'customer/getvillages/','POST',{});
    if(responsedata != null)
    {
        return {
            'villages': responsedata['villages'],
            'status': responsedata['status'],
            'message': responsedata['message']
        }
    }
}

export { loadAllCustomersData, loadVillageNames };

export default Customerhomepage;