import { useLoaderData, Link } from "react-router-dom";
import Table from "../components/Table";
import Button from "../components/Button";
import { usHttp2 } from "../custom-hooks/use-http";
import React, { useEffect, useRef, useState } from "react";
import appvariables from "../appvariables";
import Dropdown from "../components/Dropdown";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";

const Allbills = () => {
  const masterdispatcher = useDispatch();
  const { bills } = useLoaderData();
  const budata = useSelector((state) => state.budata);
  const [billss, setBillss] = useState([]);
  const [billsfortable, setBillsfortable] = useState([]);
  const [range, setRange] = useState("");
  const CreateLink = (billid) => {
    return (
      <Link
        to={`../../bill/getone/${billid}`}
        style={{
          backgroundColor: "black",
          color: "aliceblue",
          borderRadius: "20px",
          fontWeight: "300",
          textDecoration: "none",
          padding: "5px 15px",
          fontSize: "15px",
        }}
      >
        Details
      </Link>
    );
  };

  useEffect(() => {
    setBillss(bills);
  }, [bills]);

  const BillTypee = (billid) => {
    if (billid.includes("CRPY")) {
      return (
        <div
          style={{
            background: "#00c92b",
            padding: "2px 5px",
            borderRadius: "3px",
          }}
        >
          Cr
        </div>
      );
    } else if (billid.includes("CASH")) {
      return (
        <div
          style={{
            background: "#ffeb3b",
            padding: "2px 5px",
            borderRadius: "3px",
          }}
        >
          Ca
        </div>
      );
    } else {
      return <div>Bill</div>;
    }
  };

  const TranformBillDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      date.substring(8, 10) +
      "," +
      monthNames[Number(date.substring(5, 7)) - 1].substring(0, 3) +
      "," +
      date.substring(0, 4) +
      `(${date.substring(11, 16)})`
    );
  };

  const getBillsForRange = () => {
    masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
    let requestbody = {};
    if (range === "All") {
      usHttp2(appvariables.apiurl + "bill/getall/", "POST", {})
        .then((response) => response.json())
        .then((json) => setBillss(json["bills"]));
    } else {
      requestbody["range"] = range;
      usHttp2(appvariables.apiurl + "bill/getrange/", "POST", requestbody)
        .then((response) => response.json())
        .then((json) => 
        {
          setBillss(json["bills"]);
        });
    }
  };

  const tablefields = [
    {
      name: "Type",
      key: "billid",
      transformer: BillTypee,
      cs: { width: "40px" }
    },
    {
      name: 'By',
      key: 'billedby',
      filter: 1,
      cs: { width: "50px" }
    },
    { name: "customer", key: "customer", filter: 1, cs: { width: "150px" } },
    {
      name: "Date (Time)",
      key: "createddatetimestamp",
      transformer: TranformBillDate,
      cs: { width: "130px" },
    },
    { name: "Products", filter: 1, key: "products"},
    { name: "MRP", key: "totalfrommrp", cs: { width: "70px" } },
    { name: "Decided", key: "totalfinaldecided", cs: { width: "70px" } },
    { name: "Recieved", key: "totalrecieved", cs: { width: "70px" } },
    {
      name: "Link",
      key: "billid",
      transformer: CreateLink,
      cs: { width: "50px" },
    }
  ];

  const rangechange = (e) => {
    setRange(e.target.value);
  };

  useEffect(() => {
    let rev_bills = [];
    for (let i = billss.length - 1; i >= 0; i--) {
      rev_bills.push(billss[i]);
    }
    setBillsfortable(rev_bills);
    masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
  }, [billss]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ padding: "5px", margin: "10px 0px" }}>All Bills</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            alignItems: "center",
          }}
        >
          {(budata.permissions === "owner" ||
            budata.permissions === "admin") && (
            <React.Fragment>
              <Dropdown
                dropdownstyle={"compact"}
                options={[
                  "Last 14 days",
                  "Last 1 Month",
                  "Last 3 Months",
                  "Last 6 Months",
                ]}
                id={"df"}
                name={"range"}
                displayname={"Range"}
                changehandler={rangechange}
              />
              <Button
                clickhandler={getBillsForRange}
                disabled={range === ""}
                cs={{
                  fontSize: "13px",
                  fontWeight: 400,
                  padding: "5px 10px",
                  backgroundColor: range === "" && "rgba(0,0,0,0.6)",
                  borderColor: range === "" && "rgba(0,0,0,0.6)",
                }}
              >
                Get
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <Table
        data={billsfortable}
        tablefields={tablefields}
        decider={"credit"}
        rowstyling={{ backgroundColor: "rgb(255 73 73)" }}
      />
    </React.Fragment>
  );
};

export default Allbills;
