import { createSlice } from "@reduxjs/toolkit";

const initvalue  = {
    'message': '',
    'status': 'success'
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState: initvalue,
    reducers: {
        updatenotification(state, action)
        {
            state.message = action.payload.message;
            state.status = action.payload.status;
        }
    }
});

export default notificationSlice.reducer;

export const notificationsliceactions = notificationSlice.actions;