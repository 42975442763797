import { useState, useEffect, useRef } from 'react';
import classes from './Table.module.css';
import Input from './Input';
import React from "react";
import Button from './Button';

const Table = (props) => {
    const[tabledata, setTabledata] = useState(props.data);

    useEffect(()=>{
        setTabledata(props.data);
    },[props.data]);

    const[trs, setTrs] = useState(props.tablefields.length);
    
    const [column1, setColumn1] =useState('');
    const [column2, setColumn2] =useState('');
    const [column3, setColumn3] =useState('');
    const [column4, setColumn4] =useState('');
    const [column5, setColumn5] =useState('');
    const [column6, setColumn6] =useState('');
    const [column7, setColumn7] =useState('');
    const [column8, setColumn8] =useState('');
    const [column9, setColumn9] =useState('');
    const [column10, setColumn10] =useState('');

    useEffect(()=>{
        var kkk = props.data.filter((coldata) => {
            var kk = 1;

                var k1 = true;
                var k2 = true;
                var k3 = true;
                var k4 = true;
                var k5 = true;
                var k6 = true;
                var k7 = true;
                var k8 = true;
                var k9 = true;
                var k10 = true;

                if(kk++  && column1 !== '' && kk <= trs+1)
                {
                    var k11 = props.tablefields[0].transformer ? (props.tablefields[0].transformer)(coldata[props.tablefields[0].key]) : coldata[props.tablefields[0].key]
                    k1 = k11.toString().toLowerCase().indexOf(column1.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column2 !== '' && kk <= trs+1)
                {
                    var k22 = props.tablefields[1].transformer ? (props.tablefields[1].transformer)(coldata[props.tablefields[1].key]) : coldata[props.tablefields[1].key]
                    k2 = k22.toString().toLowerCase().indexOf(column2.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column3 !== '' && kk <= trs+1)
                {
                    var k33 = props.tablefields[2].transformer ? (props.tablefields[2].transformer)(coldata[props.tablefields[2].key]) : coldata[props.tablefields[2].key]
                    k3 = k33.toString().toLowerCase().indexOf(column3.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column4 !== '' && kk <= trs+1)
                {
                    var k44 = props.tablefields[3].transformer ? (props.tablefields[3].transformer)(coldata[props.tablefields[3].key]) : coldata[props.tablefields[3].key]
                    k4 = k44.toString().toLowerCase().indexOf(column4.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column5 !== '' && kk <= trs+1)
                {
                    var k55 = props.tablefields[4].transformer ? (props.tablefields[4].transformer)(coldata[props.tablefields[4].key]) : coldata[props.tablefields[4].key]
                    k5 = k55.toString().toLowerCase().indexOf(column5.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column6 !== '' && kk <= trs+1)
                {
                    var k66 = props.tablefields[5].transformer ? (props.tablefields[5].transformer)(coldata[props.tablefields[5].key]) : coldata[props.tablefields[5].key]
                    k6 = k66.toString().toLowerCase().indexOf(column6.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column7 !== '' && kk <= trs+1)
                {
                    var k77 = props.tablefields[6].transformer ? (props.tablefields[6].transformer)(coldata[props.tablefields[6].key]) : coldata[props.tablefields[6].key]
                    k7 = k77.toString().toLowerCase().indexOf(column7.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column8 !== '' && kk <= trs+1)
                {
                    var k88 = props.tablefields[7].transformer ? (props.tablefields[7].transformer)(coldata[props.tablefields[7].key]) : coldata[props.tablefields[7].key]
                    k8 = k88.toString().toLowerCase().indexOf(column8.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column9 !== '' && kk <= trs+1)
                {
                    var k99 = props.tablefields[8].transformer ? (props.tablefields[8].transformer)(coldata[props.tablefields[8].key]) : coldata[props.tablefields[8].key]
                    k9 = k99.toString().toLowerCase().indexOf(column9.toLowerCase()) === -1 ? false : true
                }
                if(kk++  && column10 !== '' && kk <= trs+1)
                {
                    var k100 = props.tablefields[9].transformer ? (props.tablefields[9].transformer)(coldata[props.tablefields[9].key]) : coldata[props.tablefields[9].key]
                    k10 = k100.toString().toLowerCase().indexOf(column10.toLowerCase()) === -1 ? false : true
                }
                return k1 && k2 && k3 && k4 && k5 && k6 && k7 && k8 && k9 && k10;
            })
        setTabledata(kkk);
    },[column1, column2, column3, column4, column5, column6, column7, column8, column9, column10 ]);

    var columns = [column1, column2, column3, column4, column5, column6, column7, column8, column9, column10 ];

    var setcolumns = [setColumn1, setColumn2, setColumn3, setColumn4, setColumn5, setColumn6, setColumn7, setColumn8, setColumn9, setColumn10 ];

    const filterchangehandler = (e, index) => {
        setcolumns[index](e.target.value);
    }

    useEffect(() => {
        if(props.filterchangehandler)
        {
            props.filterchangehandler(tabledata);
        }
    },[tabledata]);

    const gsref = useRef();

    const globalsearch = () => {
        var gstext = gsref.current.value.trim();
        var kkk = props.data.filter((coldata) => {

                var k1 = true;
                var k2 = true;
                var k3 = true;
                var k4 = true;
                var k5 = true;
                var k6 = true;
                var k7 = true;
                var k8 = true;
                var k9 = true;
                var k10 = true;

                    try{
                        var k11 = props.tablefields[0].transformer ? (props.tablefields[0].transformer)(coldata[props.tablefields[0].key]) : coldata[props.tablefields[0].key]
                        k1 = k11.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k1= false;
                    }

                    try{
                        var k22 = props.tablefields[1].transformer ? (props.tablefields[1].transformer)(coldata[props.tablefields[1].key]) : coldata[props.tablefields[1].key]
                        k2 = k22.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k2= false;
                    }

                    try{
                        var k33 = props.tablefields[2].transformer ? (props.tablefields[2].transformer)(coldata[props.tablefields[2].key]) : coldata[props.tablefields[2].key]
                        k3 = k33.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k3= false;
                    }

                    try{
                        var k44 = props.tablefields[3].transformer ? (props.tablefields[3].transformer)(coldata[props.tablefields[3].key]) : coldata[props.tablefields[3].key]
                        k4 = k44.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k4= false;
                    }

                    try{
                        var k55 = props.tablefields[4].transformer ? (props.tablefields[4].transformer)(coldata[props.tablefields[4].key]) : coldata[props.tablefields[4].key]
                        k5 = k55.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k5= false;
                    }

                    try{
                        var k66 = props.tablefields[5].transformer ? (props.tablefields[5].transformer)(coldata[props.tablefields[5].key]) : coldata[props.tablefields[5].key]
                        k6 = k66.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k6= false;
                    }

                    try{
                        var k77 = props.tablefields[6].transformer ? (props.tablefields[6].transformer)(coldata[props.tablefields[6].key]) : coldata[props.tablefields[6].key]
                        k7 = k77.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k7= false;
                    }

                    try{
                        var k88 = props.tablefields[7].transformer ? (props.tablefields[7].transformer)(coldata[props.tablefields[7].key]) : coldata[props.tablefields[7].key]
                        k8 = k88.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k8= false;
                    }

                    try{
                        var k99 = props.tablefields[8].transformer ? (props.tablefields[8].transformer)(coldata[props.tablefields[8].key]) : coldata[props.tablefields[8].key]
                        k9 = k99.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k9 = false;
                    }

                    try{
                        var k100 = props.tablefields[9].transformer ? (props.tablefields[9].transformer)(coldata[props.tablefields[9].key]) : coldata[props.tablefields[9].key]
                        k10 = k100.toLowerCase().indexOf(gstext.toLowerCase()) === -1 ? false : true
                    }
                    catch(error)
                    {
                        k10 = false;
                    }

                return k1 || k2 ||  k3 ||  k4 || k5 || k6 ||  k7 ||  k8 || k9 || k10;
        });
        setTabledata(kkk);
    }

    return(
        <div style={props.cs}>
        {props.gs && <div style={{width: '300px', display:'flex', margin: '10px 0px', gap: '10px'}}>
            <Input inputboxstyle={'compact'} type={"text"} id={'gsallbills'} displayname={'Search'} name={'search'} refvar={gsref}/>
            <Button clickhandler={globalsearch} cs={{
                  fontSize: "13px",
                  fontWeight: 400,
                  padding: "5px 10px"}}>Search</Button>
        </div>}
        <table className={classes.table}>
            <thead>
                <tr>
                    {props.tablefields.map((tablefield, index) => {
                        if(tablefield.filter)
                        {
                            return <th key={'dfg'+index} className={classes.th} style={tablefield.cs}> <Input inputboxstyle={'compact'} displayname={tablefield.name} type={'text'} changehandler={(e) => filterchangehandler(e, index)} /></th>
                        }
                        return <th key={'dfg'+index} className={classes.th} style={tablefield.cs}>{tablefield.name}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {tabledata.map((td, index) => {
                    return (<tr key={'sdfsd'+index} style={props.decider && (td[props.decider] ? 'sdf' : null ) && props.rowstyling} >
                        {props.tablefields.map((tablefield, index) => {
                            if(tablefield.transformer)
                            {
                                return <td key={'dfgsfd'+index} className={classes.td} style={tablefield.cs}>{tablefield.transformer(td[tablefield.key])}</td>
                            }
                            if(tablefield.transformerr)
                            {
                                return <td key={'dfgsfd'+index} className={classes.td} style={tablefield.cs}><Button cs={{
                                    backgroundColor: 'black',
                                    color: 'aliceblue',
                                    borderRadius: '20px',
                                    fontWeight: '300',
                                    textDecoration: 'none',
                                    padding: '5px 15px',
                                    fontSize: '15px'
                                }} clickhandler={() => tablefield.transformerr(td[tablefield.key])}>{tablefield.text}</Button></td>
                            }
                            if(tablefield.overflowhide)
                            {
                                return <td key={'dfgsfd'+index} className={classes.td} style={tablefield.cs}>
                                    <div className={tabledata.length > 20 ? classes.overflowhide : ''}>
                                        {td[tablefield.key]}
                                    </div>
                                </td>
                            }
                            return <td key={'dfgsfd'+index} className={classes.td} style={tablefield.cs}>{td[tablefield.key]}</td>
                        })}
                    </tr>)
                })}
                {props.summary && <tr>
                    {props.tablefields.map((tablefield, index) => {
                        if(tablefield.summary === 1)
                        {
                            let kk = 0;
                            for(let i=0; i<props.data.length; i++)
                            {
                                kk = kk + props.data[i][tablefield.key];
                            }
                            return <td className={classes.td} style={{...tablefield.cs}}>{kk}</td>
                        }
                        return <td className={classes.td} style={tablefield.cs} ></td>
                    })}
                    </tr>}
            </tbody>
        </table>
        </div>
    );
}

export default Table;
