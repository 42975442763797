import { Link, useLoaderData } from "react-router-dom";
import Table from "../components/Table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";

const TodayBills = () => {
    const { bills, p } = useLoaderData();
    const budata = useSelector((state) => state.budata);
    const [billsfortable, setBillsfortable] = useState([]);

    const CreateLink = (billid) => {
      return (
        <Link
          to={`../../bill/getone/${billid}`}
          style={{
            backgroundColor: "black",
            color: "aliceblue",
            borderRadius: "20px",
            fontWeight: "300",
            textDecoration: "none",
            padding: "5px 15px",
            fontSize: "15px",
          }}
        >
          Details
        </Link>
      );
    };
  
    const BillTypee = (billid) => {
      if (billid.includes("CRPY")) {
        return (
          <div
            style={{
              background: "#00c92b",
              padding: "2px 5px",
              borderRadius: "3px",
            }}
          >
            Credit Payment
          </div>
        );
      } else if (billid.includes("CASH")) {
        return (
          <div
            style={{
              background: "#ffeb3b",
              padding: "2px 5px",
              borderRadius: "3px",
            }}
          >
            Cash/Old Credit
          </div>
        );
      } else {
        return <div>Bill</div>;
      }
    };
  
    const TranformBillDate = (date) => {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return (
        // date.substring(8, 10) +
        // "," +
        // monthNames[Number(date.substring(5, 7)) - 1].substring(0, 3) +
        // "," +
        // date.substring(0, 4) +
        // `(${date.substring(11, 16)})`
        date.substring(11, 16)

      );
    };
  
    const tablefields = [
      {
        name: "Type",
        key: "billid",
        transformer: BillTypee,
        cs: { width: "50px" },
      },
      {name: 'By', key: 'billedby', cs: { width: "50px" }},
      { name: "customer", key: "customer", cs: { width: "100px" } },
      {
        name: "Time",
        key: "createddatetimestamp",
        transformer: TranformBillDate,
        cs: { width: "50px" },
      },
      { name: "Products", key: "products", cs:{ width: "300px"} },
      { name: "MRP", key: "totalfrommrp", cs: { width: "70px" } },
      { name: "Decided", key: "totalfinaldecided", cs: { width: "70px" } },
      { name: "Recieved", key: "totalrecieved", cs: { width: "70px" }},
      {
        name: "Link",
        key: "billid",
        transformer: CreateLink,
        cs: { width: "50px" },
      },
    ];
  
    useEffect(() => {
      setBillsfortable(bills);
    }, [bills]);
  
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: 'normal'
          }}
        >
          <h2 style={{ padding: "5px", margin: "10px 0px" }}>Today Bills</h2>
          <div>
          </div>
        </div>
        <Table
          data={billsfortable}
          tablefields={tablefields}
          decider={"credit"}
          rowstyling={{ backgroundColor: "rgb(255 73 73)" }}
          summary={true}
        />
        <p style={{ color: '#ffffff', alignSelf: 'start' }} >Profit : {p}</p>
      </React.Fragment>
    );
}

export default TodayBills;