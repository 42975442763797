import React, { useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import Details from "../components/Details";
import Table from "../components/Table";
import Button from "../components/Button";

const SinglePGdetails = () => {
    const { pgdetails } = useLoaderData();

    const [products, setProducts] = useState([]);
    const [detdata, setDetdata] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if(pgdetails !== undefined)
        {
            setDetdata([
                {'name': 'Name', 'value': pgdetails['name']},
                {'name': 'Minimum Quantity', 'value': pgdetails['mq']},
                {'name': 'Present Quantity', 'value': pgdetails['pgquan']},
            ]);
            setProducts(pgdetails['products']);
        }
    },[]);

    const tablefields = [
        {'name':'Name', 'key':'name'},
        {'name':'Package', cs: { width: '80px' }, 'key':'package'},
        {'name':'Company', 'key': 'company'},
        {'name': 'Quantity', cs: { width: '80px' }, 'key': 'quantity'},
    ]

    return(
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <h2>Product Group Details</h2>
                <Button cs={{padding: '5px 15px'}} clickhandler={() => navigate(`./edit`)}>Edit</Button>
            </div>
            <Details dropdownstyle={'smallvalue'} cs={{width: '500px'}} data={detdata}/>
            <h4>Products</h4>
            <Table tablefields={tablefields} data={products} />
        </div>
    )
}

export default SinglePGdetails;