import { Outlet, useNavigation } from "react-router-dom"
import Navbar from "../../components/Navbar"
import classes from './Rooutlayout.module.css'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import appvariables from "../../appvariables"
import { getCookie, usHttp, usHttp2 } from "../../custom-hooks/use-http"
import { budatasliceactions } from "../../store/budata-slice"
import { notificationsliceactions } from "../../store/notification"
import Loading from "../../components/Loading"
import { loadingsliceactions } from "../../store/loading-slice"
import { getdatasliceactions } from "../../store/getdata"

const getbupermission = (bupermissions, allowedpermissions) => {
  if(bupermissions !== undefined && bupermissions !== null)
  {
    for(let i=0; i<allowedpermissions.length; i++)
    {
      if(bupermissions === allowedpermissions[i])
      {
        return true;
      }
    }
  }
  return false;
}

export { getbupermission };

const checkpermission = (sectionlinks, section, link) => {
  if(sectionlinks === undefined)
  {
    return false;
  }
  let sectionlinkarray = sectionlinks[section];
  if(sectionlinkarray === undefined)
  {
    return false;
  }
  if(link === '' && (sectionlinkarray !== undefined))
  {
    return true;
  }
  for(let i=0; i<sectionlinkarray.length; i++)
  {
    if(Object.keys(sectionlinkarray[i])[0] === link)
    {
      return true;
    }
  }
  return false;
}

const Rootlayout = (props) => {
    const masterdispatcher = useDispatch();
    const cca = useSelector(state => state.budata.cca);
    const notification = useSelector(state => state.notification);
    const loading = useSelector(state => state.loading);

    const navigation = useNavigation();

    useEffect(() => {
      if(notification.message !== '')
      {
        setTimeout(() => {
          masterdispatcher(notificationsliceactions.updatenotification({'message': '', 'status': ''}))
        },5000);
        setTimeout(() => {
          
        })
      }
    },[notification.message]);

    useEffect(()=>{
      const kk = async () => {
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}))
        usHttp2(appvariables.apiurl + 'billinguser/getdata/','POST',{})
        .then((response) => response.json())
        .then((json) => {
          if(json.phonenumber !== 'na')
          {
            masterdispatcher(budatasliceactions.updatebucca(getCookie('cca')));
          }
          masterdispatcher(loadingsliceactions.updateloading({state: ''}));
          masterdispatcher(budatasliceactions.updatebudatastate(json));
          masterdispatcher(getdatasliceactions.updategetdata(true));
        })
      }
      kk();
    },[cca]);
    return(
        <div>
          {navigation.state !== 'idle' ? <Loading /> : loading.state === 'loading' ? <Loading /> : null}
            <Navbar />
            <div className={classes.rootoutlet}>
                <Outlet />
            </div>
            {notification.message !== '' && <div style={{position:'fixed', top: '100px', left: '50%', transform: 'translateX(-50%)', backgroundColor: notification.status === 'Success' ? 'rgb(46, 125, 50)': 'rgb(211, 47, 47)', color: 'white', borderRadius: '10px'}} >
              <p style={{margin: '10px 16px'}} >{notification.message}</p>
            </div>}
        </div>
    );
}

export default Rootlayout;

export { checkpermission };