import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import { loadingsliceactions } from "../../store/loading-slice";
import appvariables from "../../appvariables";
import { usHttp2 } from "../../custom-hooks/use-http";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

const CreateLink = (billid) => {
  return (
    <Link
      to={`/bill/getone/${billid}`}
      style={{
        backgroundColor: "black",
        color: "aliceblue",
        borderRadius: "20px",
        fontWeight: "300",
        textDecoration: "none",
        padding: "5px 15px",
        fontSize: "15px",
      }}
    >
      Details
    </Link>
  );
};

const CreateLinkForTask = (relationid) => {
  if(relationid.substring(0, 4) === "CUST")
  {
    return (
      <Link
      to={`/bill/getone/${relationid}`}
      style={{
        backgroundColor: "black",
        color: "aliceblue",
        borderRadius: "20px",
        fontWeight: "300",
        textDecoration: "none",
        padding: "5px 15px",
        fontSize: "15px",
      }}
    >
      Details
    </Link>
    )
  }
  else
  {
    return (
      <Link
      to={`/bill/getone/${relationid}`}
      style={{
        backgroundColor: "black",
        color: "aliceblue",
        borderRadius: "20px",
        fontWeight: "300",
        textDecoration: "none",
        padding: "5px 15px",
        fontSize: "15px",
      }}
    >
      Details
    </Link>
    )
  }
}


const Followupsortasks = (props) => {
  const [crvilpending, setCrvilpending] = useState([]);
  const [tasks, setTasks] = useState([]);
  const masterdispatcher = useDispatch();
  
  const getcreditvillagependingbills = () => {
    masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
    usHttp2(appvariables.apiurl + 'bill/getcrvilpending/', 'POST',{})
    .then((response) => response.json())
    .then((json) => {
      setCrvilpending(json['bills']);
      masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
    })
  }
  const ActionForTask = (taskid) => {
    return (
      <Button clickhandler={() => deleteTask(taskid)} cs={{
        backgroundColor: "black",
        color: "aliceblue",
        borderRadius: "20px",
        fontWeight: "300",
        textDecoration: "none",
        padding: "5px 15px",
        fontSize: "15px",
      }} >
        Close the Task
      </Button>
    )
  }

    const deleteTask = (taskid) => {
      masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
      usHttp2(appvariables.apiurl + 'customer/deletetask/', 'POST',{taskid: taskid})
      .then((response) => response.json())
      .then((json) => {
        if(json['status'] == 'Success')
        {
          let kkk = [...tasks];
          for(let i=0; i<tasks.length; i++)
          {
            if(tasks[i].taskid == taskid)
            {
              kkk.splice(i, 1);
            }
          }
          setTasks(kkk);
        }
          masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
      })
    }

    const getTasks = () => {
      masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        usHttp2(appvariables.apiurl + 'customer/gettasks/', 'POST',{})
        .then((response) => response.json())
        .then((json) => {
            setTasks(json['tasks']);
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
        })
    }

      const tablefieldscr = [
        {'name':'Amount', 'key':'Amount', cs: {width: '50px'}},
        {'name':'Paid','key': 'Paid', cs: {width: '50px'}},
        {name: 'Credit', key: 'Credit', cs: {width: '50px'}},
        {'name': 'Remarks', key: 'Remarks', cs: {width: '100px'}},
        {'name': 'Days', key: 'Days', cs: {width: '50px'}},
        {'name': 'Link', key: 'id', transformer: CreateLink}
      ];

      const tablefieldstasks = [
        {'name':'Date', key:'date', cs: {width: '100px'}},
        {'name':'Message', key: 'message'},
        {'name': 'Details', key: 'relationid', transformer: CreateLinkForTask, cs: {width: '80px'}},
        {'name': 'Action', key: 'taskid', transformer: ActionForTask},
      ]

      useEffect(() => {
        const date = new Date();
        if(date.getDay()%3 == 0 && date.getDay() !== 0)
        {
            getcreditvillagependingbills();
        }
        getTasks();
      },[]);

    return(
        <React.Fragment>
            <h2 style={{ margin: '10px' }}>Follow Ups/Tasks</h2>
            {
                !(crvilpending.length ===0) && <React.Fragment>
                    <Table data={crvilpending} tablefields={tablefieldscr} />
                </React.Fragment>
            }
            {
              !(tasks.length === 0) && <React.Fragment>
                  <Table data={tasks} tablefields={tablefieldstasks} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Followupsortasks;