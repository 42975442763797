import React, { useEffect, useRef, useState } from "react";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Dropdown from "../components/Dropdown";
import Button from "../components/Button";
import CustomForm from "../components/CustomForm";
import { useLoaderData, useNavigate } from "react-router-dom";
import Details from "../components/Details";
import { useDispatch } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";

const CreditPayment = () => {
    const navigate = useNavigate();
    const { vilcust } = useLoaderData();
    const cvillages = vilcust.villages;
    const vilcustomers = vilcust.vilcustobj;

    const customervillageref = useRef();
    const customernameref = useRef();
    const amountpaidref = useRef();

    const masterdispatcher = useDispatch();

    const [ villages, setVillages] = useState(cvillages);
    const [customername, setCustomername] = useState('');
    const [customervillage, setCustomervillage] = useState('');
    const [creditamount, setCreditamount] = useState(0);
    const [amountpaid, setAmountpaid] = useState(0);
    const [amountpaiderror, setAmountpaiderror] = useState('');
    const [remarks, setRemarks] = useState('');
    const remarksref = useRef();

    const [vilcustre, setVilcustre] = useState([]);

    const villagechangehandler = (e) => {
        setCustomervillage(e.target.value);
        setCustomername('');
        customernameref.current.value = '';
    }

    const custnamechangehandler = (e) => {
        setCustomername(e.target.value);
    }

    const amountpaidchangehandler = () => {
        if(amountpaidref.current.value < 0)
        {
            amountpaidref.current.value = 0;
            setAmountpaiderror("Amount paid can't be less than 0");
        }
        else if(amountpaidref.current.value > creditamount)
        {
            amountpaidref.current.value = creditamount;
            setAmountpaid(creditamount);
            setAmountpaiderror("Amount Paid can't be greater than credit Amount");
        }
        else{
            setAmountpaid(amountpaidref.current.value);
            setAmountpaiderror('');;
        }
    }

    const remarkschangehandler = () => {
        setRemarks(remarksref.current.value.trim());
    }

    const getcreditamount = () => {
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        if(customername !== '' && customervillage !== '')
        {
            usHttp2(appvariables.apiurl + 'bill/creditamount/', 'POST', { customername: customername, customervillage: customervillage })
            .then((response) => response.json())
            .then((json) => {
                if(json.amount === 0)
                {
                    masterdispatcher(notificationsliceactions.updatenotification({message: 'No Credit Found', status: 'error'}));
                }
                masterdispatcher(loadingsliceactions.updateloading({state: ''}));
                setCreditamount(json['amount'])
            });
        }
    }

    const addcreditpaymenttoserver = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        if(amountpaid > 0 && amountpaid <= creditamount)
        {
            usHttp2(appvariables.apiurl + 'bill/creditpayment/', 'POST', { customername: customername, customervillage: customervillage, amountpaid: amountpaid, remarks: remarks })
            .then((response) => response.json())
            .then((json) => 
            {
                masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
                masterdispatcher(loadingsliceactions.updateloading({state: ''}));
                navigate('/bill');
            });
        }
    }

    useEffect(() => {
        setVilcustre(vilcustomers[customervillage]);
    },[customervillage]);

    const inputs = [
        {
            type: 'number',
            id: 'tftid',
            refvar: amountpaidref,
            changehandler: amountpaidchangehandler,
            displayname: 'Amount Paid',
            name: 'amountpaid',
            max: creditamount,
            error: amountpaiderror,
        },
        {
            type: 'text',
            id: 'tftid2',
            refvar: remarksref,
            changehandler: remarkschangehandler,
            displayname: 'Remarks',
            name: 'reamrks',
        }
    ]

    const creditamountdetails = [
        {'name': 'Credit Amount', 'value': creditamount},
    ]

    return(
        <div style={{backgroundColor: amountpaid > 0 && '#92ff92'}} >
            <div style={{display: 'grid', alignItems: 'center' ,gridTemplateColumns: '1fr 1fr 1fr 1fr',width: '100%'}} >
                <Dropdown refvar={customervillageref} dropdownstyle={'compact'} id={'createbillvill'} name={'createbillvill'} displayname={'Village'} changehandler={villagechangehandler} options={villages} />
                <Dropdown refvar={customernameref} dropdownstyle={'compact'} id={'createbillcust'} name={'createbillcust'} displayname={'Customer Name'} changehandler={custnamechangehandler} options={vilcustre} />
                <div></div>
                <Button disabled={customername === '' || customervillage === ''} clickhandler={getcreditamount} cs={{backgroundColor: (customername === '' || customervillage === '') && 'rgba(0,0,0,0.6)', borderColor: (customername === '' || customervillage === '') && 'rgba(0,0,0,0.6)'}}>Add Amount</Button>
            </div>
            {(creditamount > 0) && <div style={{paddingTop: '40px'}} >
                <Details data={creditamountdetails} /> 
                <CustomForm resetdisabled={true} submitdisabled={(amountpaid === '') || (amountpaid > creditamount) || (amountpaid < 1)} inputboxstyle={'equal'} inputs={inputs} submitHandler={addcreditpaymenttoserver}/>
            </div>}
        </div>
    )
}

export default CreditPayment;