import { Link, useLoaderData, useNavigate } from "react-router-dom";
import Table from "../components/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import { loadingsliceactions } from "../store/loading-slice";
import { notificationsliceactions } from "../store/notification";

const Workerbills = () => {
    const { bills, workername } = useLoaderData();
    const budata = useSelector((state) => state.budata);
    const [billss, setBillss] = useState([]);
    const [billsfortable, setBillsfortable] = useState([]);

    const CreateLink = (billid) => {
      return (
        <Link
          to={`../../bill/getone/${billid}`}
          style={{
            backgroundColor: "black",
            color: "aliceblue",
            borderRadius: "20px",
            fontWeight: "300",
            textDecoration: "none",
            padding: "5px 15px",
            fontSize: "15px",
          }}
        >
          Details
        </Link>
      );
    };

  
    useEffect(() => {
      setBillss(bills);
    }, [bills]);
  
    const BillTypee = (billid) => {
      if (billid.includes("CRPY")) {
        return (
          <div
            style={{
              background: "#00c92b",
              padding: "2px 5px",
              borderRadius: "3px",
            }}
          >
            Credit Payment
          </div>
        );
      } else if (billid.includes("CASH")) {
        return (
          <div
            style={{
              background: "#ffeb3b",
              padding: "2px 5px",
              borderRadius: "3px",
            }}
          >
            Cash/Old Credit
          </div>
        );
      } else {
        return <div>Bill</div>;
      }
    };
  
    const TranformBillDate = (date) => {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return (
        date.substring(8, 10) +
        "," +
        monthNames[Number(date.substring(5, 7)) - 1].substring(0, 3) +
        "," +
        date.substring(0, 4) +
        `(${date.substring(11, 16)})`
      );
    };
  
    const tablefields = [
      {
        name: "Type",
        key: "billid",
        transformer: BillTypee,
        cs: { width: "40px" },
      },
      { name: "customer", key: "customer", filter: 1, cs: { width: "150px" } },
      {
        name: "Date (Time)",
        key: "createddatetimestamp",
        transformer: TranformBillDate,
        cs: { width: "130px" },
      },
      { name: "Products", filter: 1, key: "products"},
      { name: "MRP", key: "totalfrommrp", cs: { width: "100px" } },
      { name: "Decided", key: "totalfinaldecided", cs: { width: "100px" } },
      { name: "Recieved", key: "totalrecieved", cs: { width: "100px" }, summary: 1 },
      {
        name: "Link",
        key: "billid",
        transformer: CreateLink,
        cs: { width: "50px" },
      },
    ];

    const masterdispatcher = useDispatch();
    const navigate = useNavigate();

    const submitworkerbills = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        const billids = [];
        for(let i=0; i<billsfortable.length; i++){
            billids.push(billsfortable[i]['billid']);
        }
        const requestbody = {};
        requestbody['billids'] = billids;
        usHttp2(appvariables.apiurl + 'bill/submitworkerbills/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => {
            if(json.status === 'Success')
                masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
                masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
                navigate('/bill');
        })
    }
  
    useEffect(() => {
      let rev_bills = [];
      for (let i = billss.length - 1; i >= 0; i--) {
        rev_bills.push(billss[i]);
      }
      setBillsfortable(rev_bills);
    }, [billss]);
  
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: 'normal'
          }}
        >
          <h2 style={{ padding: "5px", margin: "10px 0px" }}>Bills of Employee: {workername}</h2>
          <div>
          </div>
        </div>
        <Table
          data={billsfortable}
          tablefields={tablefields}
          decider={"credit"}
          rowstyling={{ backgroundColor: "rgb(255 73 73)" }}
          summary={true}
        />
        <Button cs={{margin: '20px', backgroundColor: billsfortable.length === 0 && 'rgba(0,0,0,0.6)', borderColor: billsfortable.length === 0 && 'rgba(0,0,0,0.6)'}} disabled={billsfortable.length === 0} clickhandler={submitworkerbills} >Take Amount and submit bills</Button>
      </div>
    );
}

export default Workerbills;