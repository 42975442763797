import React from "react";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import { loadingsliceactions } from "../store/loading-slice";
import { notificationsliceactions } from "../store/notification";

const Calculations = () => {
    const masterdispatcher = useDispatch();

    const calculateProfitforall = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        let time1 = new Date();

        usHttp2(appvariables.apiurl+'bill/calculateprofitforall/', 'POST', {})
        .then((response) => response.json())
        .then((json) => 
        {
            let time2 = new Date();
            let diff = Math.ceil((time2.getTime() - time1.getTime())/1000);
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
            if(json.status === "Success")
            {
            masterdispatcher(notificationsliceactions.updatenotification({'message': 'Profit for all bills calculated in '+ diff +' seconds', 'status': json.status}));
            }
            else
            {
                masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            }
        })
    }

    const calculatectr = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        let time1 = new Date();

        usHttp2(appvariables.apiurl+'stock/calculatectrforall/', 'POST', {})
        .then((response) => response.json())
        .then((json) => 
        {
            let time2 = new Date();
            let diff = Math.ceil((time2.getTime() - time1.getTime())/1000);
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
            if(json.status === "Success")
            {
                masterdispatcher(notificationsliceactions.updatenotification({'message': 'CTR for all stocks calculated in '+ diff +' seconds', 'status': json.status}));
            }
            else
            {
                masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            }
        })
    }

    return(
        <React.Fragment>
            <h2>Calculations</h2>
            <div style={{display: 'grid', gridTemplateRows: '1fr', gap: '20px'}} >
                <Button cs={{ cursor: 'pointer'}} clickhandler={calculateProfitforall} >Calculate Profit for all Bills</Button>
                <Button cs={{ cursor: 'pointer'}} clickhandler={calculatectr}>Calculate CTR</Button>
            </div>
        </React.Fragment>
    )
}

export default Calculations;