import React from "react";
import classes from './CustomForm.module.css';
import Button from "./Button";
import Input from "./Input";

const CustomForm = (props) =>{
    const inputs = props.inputs;
    const inputstyles = props.inputboxstyle;

    return(
        <form onSubmit={props.submitHandler} className={classes.main}>
            <div className={classes.inputsection} >
            {inputs.map((inp, index) => {
                return(
                    inp.show != false ? <Input key={index} inputboxstyle={inputstyles} {...inp} /> :<></>
                )
            })}
            </div>
            <div className={classes.buttonsection}>
                {!props.resetdisabled && <Button type={'reset'} disabled={props.resetdisabled} cs={{backgroundColor: props.resetdisabled && 'rgba(0,0,0,0.6)', borderColor: props.resetdisabled && 'rgba(0,0,0,0.6)'}}>Reset</Button>}
                <Button type={'submit'} disabled={props.submitdisabled} cs={{backgroundColor: props.submitdisabled && 'rgba(0,0,0,0.6)', borderColor: props.submitdisabled && 'rgba(0,0,0,0.6)'}}>{props.submittext ? props.submittext : 'Submit'}</Button>
            </div>
        </form>
    )
}

export default CustomForm;