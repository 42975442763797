import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Table from "../components/Table";
import Details from "../components/Details";
import Button from "../components/Button";
import appvariables from "../appvariables";
import { loadingsliceactions } from "../store/loading-slice";
import { useDispatch } from "react-redux";
import { usHttp2 } from "../custom-hooks/use-http";
import Input from "../components/Input";
import { useState } from "react";

const Singlebilldetails = () => {
    const { billdetails, edit, status } = useLoaderData();
    const { billid } = useParams();
    const navigate = useNavigate();
    const masterdispatcher = useDispatch();
    const [ccc, setCcc] = useState(false);

    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const tablefields = [
        {'name': 'Name', 'key': 'name'},
        {'name': 'Package', 'key': 'package'},
        // {'name': 'Company', 'key': 'company'},
        {'name': 'Expiry Date', 'key':'expirydate', transformer: TranformDate},
        {'name': 'Price', 'key': 'price'},
        {'name': 'Individual Price', 'key':'individualpriceenteredbybuser'},
        {'name': 'Quantity', 'key': 'quantity'},
        {'name': 'Total', 'key': 'stocktotalbill'},
    ];

    const tablefieldsforreturns = [
        {'name': 'Name', 'key': 'name'},
        {'name': 'Package', 'key': 'package'},
        {'name': 'Company', 'key': 'company'},
        {'name': 'Price', 'key': 'price'},
        {'name': 'Expiry Date', 'key':'expirydate', transformer: TranformDate},
        {'name': 'Quantity', 'key': 'quantity'},
        {'name': 'Individual Price', 'key': 'indprice'},
    ];

    let detdata = [];

    if(billdetails !== undefined)
    {
        detdata = [
            {'name': 'Total From MRP', 'value': billdetails['totalfrommrp']},
            {'name': 'Total after Indi Discount', 'value': billdetails['totalafterindividualdiscount']},
            {'name': 'Total Final', 'value': billdetails['totalfinaldecided']},
            {'name': 'Discount', 'value': billdetails['totalfrommrp'] - billdetails['totalfinaldecided']},
            {'name': 'Credit', 'value': billdetails['totalfinaldecided'] - billdetails['totalrecieved']},
        ]
    }

    const clearCredit = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        usHttp2(appvariables.apiurl + 'bill/clearcredit/', 'POST',{billid: billid,})
        .then((response) => response.json())
        .then((json) => {
          if(json['status'] == 'Success')
          {
            navigate(`../getone/${billid}`);
          }
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
        })
    }


    const TranformBillDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return date.substring(8,10) + ',' + (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4) + ` (${date.substring(11,16)})`;
    }

    return (
        <>
        {status === "Success" ?  <div>
            <div style={{display: 'flex', height: '30px', flexDirection: 'row', marginLeft: '20px', alignItems: 'center'}} >
                <p style={{fontWeight: '600'}}>{billdetails['customername']}, &nbsp;</p>
                <p style={{fontWeight: '600'}}>{billdetails['customervillage']}</p>
            </div>
            <div style={{display: 'flex', height: '30px', flexDirection: 'row', justifyContent:'space-between', margin: '0px 20px', alignItems: 'center'}} >
                <p style={{fontWeight: '600'}}>{TranformBillDate(billdetails['date'])}</p>
                <p>Billed by {billdetails['billedby']}</p>
            </div>
            { billdetails['remarks'].length > 0 && <Details cs={{display: 'flex', width: '100%', margin: '0px 10px', justifyContent: 'flex-start'}} dropdownstyle={'normal'} data={[{name: 'Remarks: ', value: billdetails['remarks']}]} /> }
            <div style={{ display: 'flex', flexDirection: 'row', height: '40px', alignItems: 'center', justifyContent: 'space-between', margin: '0px 20px 5px'}} >
                <h4>Bill Details</h4>
                {(edit && billid.substring(0,4) === 'BILL') ? <Button cs={{padding: '5px 15px'}} clickhandler={() => navigate(`../${billid}/edit`)}>Edit</Button>: <div></div>}
            </div>
        <Table data={billdetails['stockdetails']} tablefields={tablefields} />
        <Details dropdownstyle={'smallvalue'} cs={{width: '30%', marginRight: '0px'}} data={detdata}/>
        { billdetails['returns'].length > 0 && <>
            <h1>Return Details</h1>
            <Table data={billdetails['returns']} tablefields={tablefieldsforreturns} />
        </> }
        {
            billdetails['profit'] !== undefined ? <p style={{ color: '#ffffff' }} >Profit for the bill is: {billdetails['profit']}</p> : <></>
        }
        {
            billdetails['profit'] !== undefined &&  billdetails['totalfinaldecided'] > billdetails['totalrecieved'] && billdetails['customername'] == "creditname" ? 
            <div style={{width: '500px', margin: '0 auto', display: 'flex'}} > 
            <Input changehandler = {(e) => setCcc(e.target.checked)} inputboxstyle={'equal'} type='checkbox' id='sfgs' name='confirmclearcredit' displayname='Confirm Clear Credit' />
            {ccc ? <Button clickhandler={() => clearCredit()}>Clear Credit</Button> : <></>}
            </div>:<></>
        }
        </div> : <>
        Unable to get Bill Details
        </> }
        </>
    );
}

export default Singlebilldetails;