import { Link } from "react-router-dom";
import classes from './homepage.module.css';
import { useSelector } from "react-redux";
import { usHttp } from "../../custom-hooks/use-http";
import appvariables from "../../appvariables";

const Billhomepage = () => {
    var billsectionlinks = useSelector(state => state.budata.sectionlinks.bill);

    if(billsectionlinks === undefined)
    {
        billsectionlinks = []
    }

    return(
        <div className={classes.homepage}>
            {billsectionlinks.map((billsectionlink, index)=> 
                <Link
                 key={'kkl'+index}
                 to={Object.keys(billsectionlink)[0]}>
                    {Object.values(billsectionlink)[0]}
                 </Link>
            )}
        </div>
    )
}

const loadCreditBillCustomerData = async () => {
    var returnobj = {}
    const responsedata = await usHttp(appvariables.apiurl+'customer/getvilcust/','POST',{});
    if(responsedata != null)
    {
        returnobj['vilcust'] =  {
            'villages': responsedata['villages'],
            'vilcustobj': responsedata['vilcustobj'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
    return returnobj;
}

const loadCreateBill = async () => {
    var returnobj = {}
    const responsedata = await usHttp(appvariables.apiurl+'customer/getvilcust/','POST',{});
    const responsedata2 = await usHttp(appvariables.apiurl+'stock/getdata/','POST',{});
    if(responsedata != null)
    {
        returnobj['vilcust'] =  {
            'villages': responsedata['villages'],
            'vilcustobj': responsedata['vilcustobj'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
    if(responsedata2 != null)
    {
        returnobj['stocksdata'] =  {
            'stocksdata': responsedata2['stocksdata'],
            'status': responsedata2['status'],
            'message': responsedata2['message']
        };
    }
    return returnobj;
}

const allbillsloader = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'bill/getall/','POST',{})
    return {
        'bills': responsedata['bills'],
        'status': responsedata['status'],
        'message': responsedata['message'],
    }
}

const rangebillsloader = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'bill/getrange/','POST',{"range": "Last 7 days"})
    return {
        'bills': responsedata['bills'],
        'status': responsedata['status'],
        'message': responsedata['message'],
    }
}

const singlebillloader = async ({ params }) => {
    const responsedata = await usHttp(appvariables.apiurl+ 'bill/getone/', 'POST', { 'billid': params.billid })
    return {
        'billdetails': responsedata['billdetails'],
        'edit': responsedata['edit'],
        'status': responsedata['status'],
        'message': responsedata['message']
    }
}

const todaybills = async ({ params }) => {
    const responsedata = await usHttp(appvariables.apiurl + 'bill/today/', 'POST', {})
    return {
        'bills': responsedata['bills'],
        'p': responsedata['tp'],
        'status': responsedata['status'],
        'message': responsedata['message']
    }
}

const workerbills = async({ params }) => {
    const responsedata = await usHttp(appvariables.apiurl+ 'bill/getworkerbills/', 'POST', { 'workerid': params.workerid })
    return {
        'workername': responsedata['workername'],
        'bills': responsedata['bills'],
        'status': responsedata['status'],
        'message': responsedata['message']
    }
}

export { loadCreateBill, loadCreditBillCustomerData, allbillsloader, rangebillsloader, singlebillloader, workerbills, todaybills };

export default Billhomepage;