import { useState } from "react";
import appvariables from "../appvariables";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { budatasliceactions } from "../store/budata-slice";
import Button from "../components/Button";


const LogoutPage = () => {
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    const masterdispatcher = useDispatch();
    const navigate = useNavigate();
    const reloadpage = () => {
        navigate('/');
    }

    const logout = () => {
        
        document.cookie = `cca=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${appvariables.url}; path=/;`;
        masterdispatcher(budatasliceactions.updatebucca(''));
        reloadpage();
    }


    return(
        <>
            <Button clickhandler={logout}>Logout</Button>
        </>
    );
}

export default LogoutPage;