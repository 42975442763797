import React from "react";
import Table from "../components/Table";
import { Link, useLoaderData } from "react-router-dom";

const AllProductGroups = () => {

    const { pgs } = useLoaderData();

    const PGLink = (billid) => {
        return (
          <Link
            to={`../productgroup/${billid}`}
            style={{
              backgroundColor: "black",
              color: "aliceblue",
              borderRadius: "20px",
              fontWeight: "300",
              textDecoration: "none",
              padding: "5px 15px",
              fontSize: "15px",
            }}
          >
            Details
          </Link>
        );
      };

    const tablefields = [
        {'name':'Name','filter':1,'key':'name','link':0, cs:{width: '100px'} },
        {'name':'Products','filter':1,'key':'products','link':0, cs:{width: '200px'} },
        {'name':'Minimum Quantity','filter':1,'key':'minimumquantity','link':0, cs:{width: '100px'} },
        {'name': 'Details', 'key': 'id', transformer: PGLink, cs:{width: '60px'} }
    ]
    return(
        <React.Fragment>
            <Table tablefields={tablefields} data={pgs} />
        </React.Fragment>
    )
}

export default AllProductGroups;