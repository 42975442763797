import React from 'react';
import classes from './Input.module.css';
import { useState, useEffect } from 'react';

const Input = (props) => {

    const [inputbox, setInputbox] = useState();
    const [isinitial, setIsinitial] = useState(1);

    const [inputclass, setInputclass] = useState('normal');

    useEffect(() => {
        setIsinitial(1);
    },[props])

    if(isinitial)
    {
        if(props.inputboxstyle === 'normal' || props.inputboxstyle === 'equal')
        {
            setInputbox(
                    ((props.type === 'number') || (props.type === "month")) ?
                    <><label className={classes.ilabel} htmlFor={props.id} >{props.displayname}</label>
                    <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} min={props.min} max={props.max} /> <p className={classes.errormessage}>{props.error}</p></div>
                    </>:
                    <><label className={classes.ilabel} htmlFor={props.id} >{props.displayname}</label>
                    <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} /><p className={classes.errormessage}>{props.error}</p></div>
                    </>
            )
        }
        if(props.inputboxstyle === 'compact')
        {
            setInputbox(((props.type === 'number') || (props.type === "month")) ?
            <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} placeholder={props.displayname} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} min={props.min} max={props.max} /><p className={classes.errormessage}>{props.error}</p>
            </div>:
            <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} placeholder={props.displayname} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} /><p className={classes.errormessage}>{props.error}</p>
            </div>)
        }
        if(props.inputboxstyle === 'compactwithlabels')
        {
            setInputbox(
                    ((props.type === 'number') || (props.type === "month")) ?
                    <><label className={classes.ilabel} htmlFor={props.id} >{props.displayname}</label>
                    <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} min={props.min} max={props.max} /> <p className={classes.errormessage}>{props.error}</p></div>
                    </> :
                    <><label className={classes.ilabel} htmlFor={props.id} >{props.displayname}</label>
                    <div><input readOnly={props.readonly} className={classes.iinput} list={"list"+props.id} name={props.name} type={props.type} id={props.id} ref={props.refvar} onBlur={props.blurhandler} onChange={props.changehandler} /><p className={classes.errormessage}>{props.error}</p></div>
                    </>
            )
        }
        setIsinitial(0);
        if(props.inputboxstyle === 'compact')
        {
            setInputclass(classes.compact);
        }
        if(props.inputboxstyle === 'compactwithlabels')
        {
            setInputclass(classes.compactwithlabels);
        }
        if(props.inputboxstyle === 'normal')
        {
            setInputclass(classes.normal);
        }
        if(props.inputboxstyle === 'equal')
        {
            setInputclass(classes.equal);
        }
    }

    return(
        <div className={inputclass}>
            {inputbox}
            <datalist id={"list"+props.id}>
                { props.datalist ? props.datalist.map((dlitem, index) => {
                    return <option value={dlitem}></option>
                }): null }
            </datalist>
        </div>
    )
}

export default Input;