import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import CustomForm from "../components/CustomForm";
import { useDispatch } from "react-redux";
import appvariables from "../appvariables";
import { notificationsliceactions } from "../store/notification";
import { usHttp2 } from "../custom-hooks/use-http";
import { loadingsliceactions } from "../store/loading-slice";

const Casholdcredit = () => {
    const navigate = useNavigate();
    const { vilcust } = useLoaderData();
    const cvillages = vilcust.villages;
    const vilcustomers = vilcust.vilcustobj;

    const customervillageref = useRef();
    const customernameref = useRef();
    const amountref = useRef();

    const masterdispatcher = useDispatch();

    const [ villages, setVillages] = useState(cvillages);
    const [customername, setCustomername] = useState('');
    const [customervillage, setCustomervillage] = useState('');
    const [amount, setAmount] = useState(0);
    const [amounterror, setAmounterror] = useState('');

    const [remarks, setRemarks] = useState('');
    const remarksref = useRef();

    const [vilcustre, setVilcustre] = useState([]);

    const villagechangehandler = (e) => {
        setCustomervillage(e.target.value);
        setCustomername('');
        customernameref.current.value = '';
    }

    const custnamechangehandler = (e) => {
        setCustomername(e.target.value);
    }

    const remarkschangehandler = () => {
        setRemarks(remarksref.current.value.trim());
    }

    const amountchange = () => {
        if(amountref.current.value < 1)
        {
            amountref.current.value = 1;
            setAmounterror("Amount paid can't be less than 1");
        }
        else{
            setAmount(amountref.current.value);
            setAmounterror('');
        }
    }

    const addcreditpaymenttoserver = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        if(amount > 0)
        {
            usHttp2(appvariables.apiurl + 'bill/casholdcredit/', 'POST', { customername: customername, customervillage: customervillage, amount: amount, remarks: remarks })
            .then((response) => response.json())
            .then((json) => 
            {
                masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
                masterdispatcher(loadingsliceactions.updateloading({state: ''}));
                navigate('/bill');
            });
        }
    }

    useEffect(() => {
        setVilcustre(vilcustomers[customervillage]);
    },[customervillage]);

    const inputs = [
        {
            type: 'number',
            id: 'tfttyyid',
            refvar: amountref,
            changehandler: amountchange,
            displayname: 'Amount',
            name: 'amount',
            min: 1,
            error: amounterror,
        },
        {
            type: 'text',
            id: 'tftid2',
            refvar: remarksref,
            changehandler: remarkschangehandler,
            displayname: 'Remarks',
            name: 'reamrks',
        }
    ]


    return(
        <div>
            <div style={{display: 'grid', alignItems: 'center' ,gridTemplateColumns: '1fr 1fr',width: '100%'}} >
                <Dropdown refvar={customervillageref} dropdownstyle={'compact'} id={'createbillvill'} name={'createbillvill'} displayname={'Village'} changehandler={villagechangehandler} options={villages} />
                <Dropdown refvar={customernameref} dropdownstyle={'compact'} id={'createbillcust'} name={'createbillcust'} displayname={'Customer Name'} changehandler={custnamechangehandler} options={vilcustre} />
            </div>
            <div style={{paddingTop: '40px'}} >
                <CustomForm resetdisabled={true} submitdisabled={amount === '' || amount < 1 || customername === ''} inputboxstyle={'equal'} inputs={inputs} submitHandler={addcreditpaymenttoserver}/>
            </div>
        </div>
    )
}

export default Casholdcredit;