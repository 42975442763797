import { configureStore } from "@reduxjs/toolkit";
import budataSlicereducer from "./budata-slice";
import notificationSliceReducer from "./notification";
import redirectSliceReducer from "./redirect-slice";
import loadingSliceReducer from "./loading-slice";
import getDataSliceReducer from "./getdata";

const store = configureStore({
    reducer: {
        'budata': budataSlicereducer,
        'notification': notificationSliceReducer,
        'redirect': redirectSliceReducer,
        'loading': loadingSliceReducer,
        'getdata': getDataSliceReducer
    }
});

export default store;