import React, { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import CustomForm from "../components/CustomForm";
import Table from "../components/Table";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import { loadingsliceactions } from "../store/loading-slice";
import { useDispatch } from "react-redux";
import { notificationsliceactions } from "../store/notification";

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const CreateProductGroup = () => {
    const [name, setName] = useState('');
    const [mq, setMq] = useState(0);
    const nameref = useRef();
    const mqref = useRef();

    const [nameerror, setNameerror] = useState('');
    const [mqerror, setMqerror] = useState('');

    const masterdispatcher = useDispatch();

    const [pname, setPname] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const psref = useRef();
    const [pserror, setPserror] = useState('');
    const [products, setProducts] = useState([]);

    const { productstrings } = useLoaderData();

    const [productstringset, setProductstringset] = useState(new Set());

    useEffect(() => {
        const kk = new Set(productstrings);
        setProductstringset(kk);
    }, [productstrings]);

    const nameinputchange = () => {
        setName(nameref.current.value.trim());
    }

    const nameinputblur = () => {
        if(nameref.current.value.trim().length < 8)
        {
            setNameerror('Name should be minimum 8 letters');
            nameref.current.value = name;
        }
        else
        {
            setNameerror('');
        }
    }

    const mqinputchange = () => {
        setMq(Math.max(0, mqref.current.value))
    }


    const psinputchange = () => {
        var product_var = (psref.current.value).split('---');
        if(product_var.length === 3)
        {
            setPname(product_var[0]);
            setPackagee(product_var[1]);
            setCompany(product_var[2]);
            psinputblur();
        }
        else
        {
            setPname('');
            setPackagee('');
            setCompany('');
        }
    }

    const psinputblur = () => {
        if(psref.current.value.trim() === '')
        {
            setPserror('Select Product');
        }
        else if(!(productstringset.has(psref.current.value)))
        {
            setPserror('Select Product from options if the required product is not available add that in Add Product Page');
        }
        else{
            setPserror('');
        }
    }

    const addpstolocal = (e) => {
        e.preventDefault();
        let newdata = {
            'name': pname,
            'package': packagee,
            'company': company,
            'rowkey': uuidv4()
        }
        let temp = [...products];
        temp.push(newdata);
        setProducts(temp);
        setPname('');
        setPackagee('');
        setCompany('');
        psref.current.focus();
        psref.current.value = ''
        setPserror('');
    }

    const deleteRow = (rowkeyy) => {
        var temp_rows = [...products];
        for(var i=0; i < temp_rows.length; i++)
        {
            if(temp_rows[i].rowkey === rowkeyy)
            {
                temp_rows.splice(i, 1);
                break;
            }
        }
        setProducts(temp_rows);
    }

    const inputsforpsadd = [
        {
            id:'cpgproduct',
            displayname: 'Product',
            name: 'product',
            type: 'text',
            refvar: psref,
            error: pserror,
            changehandler: psinputchange,
            datalist: productstrings,
            blurhandler: psinputblur
        }
    ]

    const inputforcpgsubmit = [
        {
            id:'cpgname',
            displayname: 'Name',
            name: 'name',
            type: 'text',
            refvar: nameref,
            error: nameerror,
            changehandler: nameinputchange,
            blurhandler: nameinputblur
        },
        {
            id:'cpgmq',
            displayname: 'Minimum Quantity',
            name: 'mq',
            type: 'number',
            refvar: mqref,
            error: mqerror,
            min: 0,
            changehandler: mqinputchange
        }
    ]

    const tablefields = [
        {'name':'Name', 'key':'name'},
        {'name':'Package', 'key':'package'},
        {'name':'Company', 'key':'company'},
        {'name': 'Delete', 'key': 'rowkey', transformerr: deleteRow, text: 'X', cs: { width: "70px" }},
    ]

    const createProductGroup = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        const data = {
            'name': name,
            'minimumquantity': mq,
            'products': products
        }
        usHttp2(appvariables.apiurl + 'product/createproductgroup/', 'POST', data)
        .then((response) => response.json())
        .then((json) => {
            masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));

            if(json.status === 'Success')
            {
                setName('');
                setCompany('');
                setMq(0);
                setPname('');
                setMqerror('');
                setNameerror('');
                setProducts([]);
                setPserror('');
                psref.current.value = '';
                nameref.current.value = '';
                mqref.current.value = '';
            }
        });
    }

    return (
        <React.Fragment>
            <h4>Create Product Group</h4>
            <CustomForm resetdisabled={true} submitdisabled={pname.length ===0 ||  pserror !== ''} inputboxstyle={'equal'} inputs={inputsforpsadd} submitHandler={addpstolocal} />
            <Table data={products} tablefields={tablefields} />
            <CustomForm resetdisabled={true} submitdisabled={products.length === 0 || name.length < 8 || mq < 0} inputboxstyle={"equal"} inputs={inputforcpgsubmit} submitHandler={createProductGroup} />
        </React.Fragment>
    )
}

export default CreateProductGroup;