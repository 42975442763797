import React, { useEffect, useState } from "react";
import { usHttp, usHttp2 } from "../../custom-hooks/use-http";
import appvariables from "../../appvariables";
import Table from "../../components/Table";
// import { loadingsliceactions } from "../../store/loading-slice";
// import { useDispatch } from "react-redux";

const ExpStock = (props) => {
    const [expstock, setExpstock] = useState([]);
    // const [productstable, setProductstable] = useState([]);
    // const [productgroupstable, setProductgroupstable] = useState([]);
    // const masterdispatcher = useDispatch();

    // const getminreport = () => {
    //     masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
    //     usHttp2(appvariables.apiurl + 'product/getminreport/', 'POST',{})
    //     .then((response) => response.json())
    //     .then((json) => {
    //         setProductstable(json['products']);
    //         setProductgroupstable(json['productgroups'])
    //         masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
    //     })
    // }

    const getExpStock = () => {
        let requestbody = {}
        usHttp2(appvariables.apiurl+'stock/getexpstocks/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => setExpstock(json["exstocks"]));
    }

    useEffect(() => {
        if(expstock.length > 0)
        {
            props.msgupdater('Some Stock is near to Expire or Expired');
        }
    },[expstock])

    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const tablefields = [
        {'name': 'Name', 'key':'Name'},
        {'name': 'Pack', 'key':'Package'},
        {'name': 'Batch Id', 'key':'BatchId'},
        {'name': 'Quan', 'key':'Quantity'},
        {'name': 'Price', 'key':'Price'},
        {'name': 'Expiry', 'key': 'ExpiryDate', transformer: TranformDate},
    ]

    // const tablefields2 = [
    //     {'name':'Name','key':'Name'},
    //     {'name':'Package','key':'Package'},
    //     {'name':'Company','key': 'Company'},
    //     {'name': 'Quantity', key: 'Quantity'},
    //   ];

    //   const tablefields3 = [
    //     {'name':' PG Name', 'key':'name'},
    //     {'name':'Products','key': 'ps', cs: {width: '300px'}},
    //     {'name': 'Min Quantity', key: 'mq', cs: {width: '90px'}},
    //     {'name': 'Quantity', key: 'pq', cs: {width: '80px'}},
    //   ];

    // useEffect(() => {
    //     const date = new Date();
    //     if(date.getDay()%3 == 0)
    //     {
    //         getminreport();
    //     }
    //   },[]);


    useEffect(() => {
        getExpStock();
    },[])

    // useEffect(() => {
    //     if(productstable.length + productgroupstable.length)
    //     {
    //         props.msgupdater('Some Stock is near to reach Minimum Quantity or already reached');
    //     }
    //   }, [productstable, productgroupstable]);

    return(
        <React.Fragment>
            {
                expstock.length !== 0 &&
                <React.Fragment>
                    <h2 style={{margin: '10px'}}>Exp Stock</h2>
                    <Table data={expstock} tablefields={tablefields} />
                </React.Fragment>
            }
            {/* {
                productstable.length + productgroupstable.length !== 0 && <h2>Minimum Report</h2>
            }
            {
                !(productstable.length ===0) && <React.Fragment>
                    <Table data={productstable} tablefields={tablefields2} />
                </React.Fragment>
            }
            {
                !(productgroupstable.length ===0) && <React.Fragment>
                    <Table data={productgroupstable} tablefields={tablefields3} />
                </React.Fragment>
            } */}
        </React.Fragment>
    );
}

export default ExpStock;