import { useEffect, useState } from 'react';
import classes from './Details.module.css';

const Details = (props) => {
    const [dropdownstyle, setDropdownstyle] = useState(classes.equal);

    useEffect(() => {
        if(props.dropdownstyle === 'normal')
        {
            setDropdownstyle(classes.normal);
        }
        else if(props.dropdownstyle === 'smallvalue')
        {
            setDropdownstyle(classes.smallvalue);
        }
    },[]);    

    return(
        <div className={classes.main} style={props.cs} >
            {props.data.map((da, index) => {
                return(
                    <div className={dropdownstyle}>
                    <p>{da.name}</p>
                    <p>{da.value}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Details