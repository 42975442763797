import { useLoaderData } from "react-router-dom"
import Table from "../components/Table";
import React from "react";
import Button from "../components/Button";

const Allcustomers = () => {
    const { customers } = useLoaderData();

    const PhoneLink = (phonenumber) => {
        return <Button cs={{
            backgroundColor: "black",
            color: "aliceblue",
            borderRadius: "20px",
            fontWeight: "300",
            textDecoration: "none",
            padding: "5px 15px",
            fontSize: "15px",
          }}>
            <a style={{textDecoration: 'none', color: 'white'}} href={"tel:+91"+phonenumber} >
                Call
            </a>
        </Button> 
    }

    const tablefields = [
        {'name':'Name','filter':1,'key':'Name','link':0, cs:{width: '200px'} },
        {'name':'Village','filter':1,'key':'Village','link':0, cs:{width: '150px'} },
        {'name':'Phone Number','filter':1,'key':'PhoneNumber','link':0, cs:{width: '100px'} },
        {'name': 'Call', 'key': 'PhoneNumber', transformer: PhoneLink, cs:{width: '60px'} }
    ]
    return(
        <React.Fragment>
            <Table data={customers} tablefields={tablefields} />
        </React.Fragment>
    )
}

export default Allcustomers;