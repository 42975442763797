import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import { useDispatch } from "react-redux";
import Table from "../components/Table";
import { loadingsliceactions } from "../store/loading-slice";

const MinimumQuantityReport = () => {
    const [productstable, setProductstable] = useState([]);
    const [productgroupstable, setProductgroupstable] = useState([]);
    const masterdispatcher = useDispatch()

    const getminreport = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        usHttp2(appvariables.apiurl + 'product/getminreport/', 'POST',{})
        .then((response) => response.json())
        .then((json) => {
            setProductstable(json['products']);
            setProductgroupstable(json['productgroups'])
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
        })
    }

    const tablefields = [
        {'name':'Name','filter':1,'key':'Name'},
        {'name':'Package','filter':1,'key':'Package'},
        {'name':'Company','filter':1, 'key': 'Company'},
        {'name': 'Min Quantity', key: 'MinimumQuantity'},
        {'name': 'Quantity', key: 'Quantity'},
      ];

      const tablefields2 = [
        {'name':'Name','filter':1,'key':'name'},
        {'name': 'Description', 'key': 'description'},
        {'name':'Products','filter':1, 'key': 'ps', cs: {width: '300px'}},
        {'name': 'Min Quantity', key: 'mq', cs: {width: '90px'}},
        {'name': 'Quantity', key: 'pq', cs: {width: '90px'}},
      ];

      useEffect(() => {
        getminreport();
      },[])

    return (
        <React.Fragment>
            {
                !(productstable.length ===0) && <React.Fragment>
                    <h3>Products</h3>
                    <Table data={productstable} tablefields={tablefields} />
                </React.Fragment>
            }

            {
                !(productgroupstable.length ===0) && <React.Fragment>
                    <h3>Product Groups</h3>
                    <Table data={productgroupstable} tablefields={tablefields2} />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default MinimumQuantityReport;