import { createSlice } from "@reduxjs/toolkit";
import { usHttp } from "../custom-hooks/use-http";
import appvariables from "../appvariables";

const initvalue  = {
    'getdata': false
}

const getDataSlice = createSlice({
    name: 'getdata',
    initialState: initvalue,
    reducers: {
        updategetdata(state, action)
        {
            state.getdata = action.payload;
        }
    }
});

export default getDataSlice.reducer;

export const getdatasliceactions = getDataSlice.actions;