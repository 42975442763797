import { useLoaderData } from "react-router-dom"
import Table from "../components/Table";
import React from "react";

const Allproducts = () => {
    const { products } = useLoaderData();

    const tablefields = [
        {'name': 'Id', 'key': 'id', cs: {width: '50px'}},
        {'name':'Name','filter':1,'key':'Name'},
        {'name':'Package','filter':1,'key':'Package', cs: {width: '100px'}},
        {'name':'Company','filter':1, 'key': 'Company'},
        {'name': 'Quantity', 'key': 'Quantity', cs: {width: '80px'}},
        {'name': 'Other Details', 'key': 'OtherDetails', cs: {width: '250px'}}
        // {'name':'Minimum Quantity','key':'MinimumQuantity'},
    ]
    return(
        <React.Fragment>
            <Table data={products} tablefields={tablefields} />
        </React.Fragment>
    )
}

export default Allproducts;