import React, { useEffect, useRef, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import Button from "../components/Button";
import { getCookie, usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Table from "../components/Table";
import Details from "../components/Details";
import { loadingsliceactions } from "../store/loading-slice";
import { useDispatch } from "react-redux";

const Singlecustomerdetails = () => {
    const { vilcust } = useLoaderData();
    const [vilcustre, setVilcustre] = useState([]);
    const cvillages = vilcust.villages;
    const vilcustomers = vilcust.vilcustobj;
    const [bills, setBills] = useState([]);
    
    
    const [ villages, setVillages] = useState(cvillages);
    const [customername, setCustomername] = useState('');
    const [customervillage, setCustomervillage] = useState('');
    const [ini, setInitial] = useState(false);
    const [p, setP] = useState(undefined);
    const [pn, setPn] = useState(undefined);
    const masterdispatcher = useDispatch();

    const TranformBillDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return date.substring(8,10) + ',' + (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4) + ` (${date.substring(11,16)})`;
    }

    const getCustomerDetails = () => {
        var requestbody = {};
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        requestbody["customername"] = customername;
        requestbody["customervillage"] = customervillage;

        usHttp2(appvariables.apiurl+'bill/getforacustomer/','POST',requestbody)
        .then(responsee => responsee.json())
        .then(json => {
            let rev_bills = [];
            for (let i = json.bills.length - 1; i >= 0; i--)
            {
                rev_bills.push(json.bills[i]);
            }
            setBills(rev_bills);
            setInitial(true);
            setP(json.profit);
            setPn(json.phonenumber)
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
    }

    const customervillageref = useRef();
    const customernameref = useRef();

    const villagechangehandler = (e) => {
        setCustomervillage(e.target.value);
        setCustomername('');
        customernameref.current.value = '';
        setBills([]);
        setP(undefined);
        setPn(undefined);
    }

    const custnamechangehandler = (e) => {
        setCustomername(e.target.value);
        setBills([]);
        setP(undefined);
        setPn(undefined);
    }

    useEffect(() => {
        setVilcustre(vilcustomers[customervillage]);
    },[customervillage]);

    useEffect(() => {
        var total = 0;
        var balance = 0;
        for(let i=0; i< bills.length; i++)
        {
            if(bills[i]['billid'].substring(0,4) === 'BILL' || bills[i]['billid'].substring(0,4) === 'CASH')
            {
                total = total + parseInt(bills[i]['totalfinaldecided']);
                balance = balance + parseInt(bills[i]['totalfinaldecided']) - parseInt(bills[i]['totalrecieved'])
            }
            else
            {
                balance = balance - parseInt(bills[i]['totalrecieved'])
            }
        }
        setTotal(total);
        setBalance(balance);
    },[bills]);

    const CreateLink = (billid) => {
        return(
            <Link to={`../../bill/getone/${billid}`} style={{
                backgroundColor: 'black',
                color: 'aliceblue',
                borderRadius: '20px',
                fontWeight: '300',
                textDecoration: 'none',
                padding: '5px 15px',
                fontSize: '15px'
            }} >Details</Link>
        )
    }

    const BillTypee = (billid) => {
        if(billid.includes('CRPY'))
        {
            return <div style={{background: '#00c92b', padding: '2px 5px', borderRadius: '3px'}}>Cr</div>
        }
        else if(billid.includes('CASH'))
        {
            return <div style={{background: '#ffeb3b', padding: '2px 5px', borderRadius: '3px'}}>Ca</div>
        }
        else
        {
            return <div>Bill</div>
        }
    }

    const tablefields = [
        {'name': 'Type' , 'key': 'billid', transformer: BillTypee, cs:{ width: '100px' }},
        {name: 'By', key: 'billedby', filter: 1, cs: { width: "50px" }},
        {'name': customername == 'creditname' ? 'Remarks' : '', 'key': customername == 'creditname' ? 'remarks' : '', cs: {width: !customername == 'creditname' ? '100px': '0px', display: !customername == 'creditname' ? 'none': 'normal'}, filter: 1},
        {'name':'Date (Time)', 'key': 'createddatetimestamp', transformer: TranformBillDate, cs: {width: '130px'}},
        {'name':'Products', filter: 1, 'key': 'products'},
        {'name':'MRP', 'key': 'totalfrommrp',cs:{ width: '100px' }},
        {'name':'Decided', 'key': 'totalfinaldecided',cs:{ width: '100px' }},
        {'name':'Recieved', 'key': 'totalrecieved',cs:{ width: '100px' }},
        {'name':'Link', 'key': 'billid', transformer: CreateLink, cs:{ width: '50px'}},
    ];

    const [balance, setBalance] = useState(0);
    const [total, setTotal] = useState(0);

    return (
        <div>
            <div style={{display: 'grid', alignItems: 'center' ,gridTemplateColumns: '1fr 1fr 1fr 1fr',width: '100%', margin: '30px 0px'}} >
                <Dropdown refvar={customervillageref} dropdownstyle={'compact'} id={'createbillvill'} name={'createbillvill'} displayname={'Village'} changehandler={villagechangehandler} options={villages} />
                <Dropdown refvar={customernameref } dropdownstyle={'compact'} id={'createbillcust'} name={'createbillcust'} displayname={'Customer Name'} changehandler={custnamechangehandler} options={vilcustre} />
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                    <div>
                        { pn != undefined ? <p>Credit: {balance}</p> : <></> }
                    </div>
                    <div>
                    { pn != undefined ? <Button cs={{
                        backgroundColor: "black",
                        color: "aliceblue",
                        borderRadius: "20px",
                        fontWeight: "300",
                        textDecoration: "none",
                        padding: "5px 15px",
                        fontSize: "15px",
                    }}>
                        <a style={{textDecoration: 'none', color: 'white'}} href={"tel:+91"+pn} >
                            Call
                        </a>
                    </Button> : <></> }
                    </div>
                </div>
                <Button disabled={customername === '' || customervillage === ''} clickhandler={getCustomerDetails} cs={{backgroundColor: (customername === '' || customervillage === '') && 'rgba(0,0,0,0.6)'}}>Get Details</Button>
            </div>

            <Table data={bills} tablefields={tablefields} decider={'credit'} rowstyling={{ backgroundColor: 'rgb(255 73 73)' }}/>
            <Details data={[{'name': 'Total', 'value': total},{'name':'Balance', 'value': balance}]} />
            {
                p !== undefined ? <p style={{ color: '#ffffff' }} >Profit for the bill is: {p}</p> : <></>
            }
        </div>
    )
}

export default Singlecustomerdetails;