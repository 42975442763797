import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import appvariables from "../appvariables";
import { useDispatch, useSelector } from "react-redux";
import { redirectsliceactions } from "../store/redirect-slice";
import { loadingsliceactions } from "../store/loading-slice";

const HomepageRedirect = () => {
    const navigate = useNavigate();
    const masterdispatcher = useDispatch();
    const redirect = useSelector((state) => state.redirect);
    const [navf, setNavf] = useState("");
    const pteref = useRef();
    


    useEffect(() => {
        masterdispatcher(redirectsliceactions.updateredirectinit(false));
        if (redirect.to !== "/" && redirect.to != null) {
          masterdispatcher(loadingsliceactions.updateloading({ state: "loading" }));
          const rto = redirect.to;
          setNavf(rto);
        }
      }, []);

      const getdatastate = useSelector(state => state.getdata.getdata);
    
      useEffect(() => {
        if (pteref !== undefined && pteref !== null && getdatastate ) {
          setTimeout(() => {
            pteref.current.click();
            masterdispatcher(redirectsliceactions.updateredirectto(null));
            masterdispatcher(loadingsliceactions.updateloading({ state: "" }));
          }, 1000);
        }
      }, [getdatastate]);


    return (
        <React.Fragment>
            <div style={{ display: "none" }}>
                <Link ref={pteref} to={(navf !== "" && navf !== null) ? navf : '/home'}>
                    Previosuly tried endpoint
                </Link>
            </div>
            {
                redirect.to === null ?
                <h2>Redirecting to Homepage</h2> : <h2>Redirecting to Requested Page</h2>
            }
        </React.Fragment>
    )
}

export default HomepageRedirect;