import React from "react";
import { NavLink } from "react-router-dom"
import classes from './Navbar.module.css'
import { useSelector } from "react-redux";

const Navbar = (props) => {
    const navbarlinks = useSelector(state => state.budata.navbarlinks);
    const navbarhide = useSelector(state => state.budata.navbarhide);

    return(
        <div className={classes.navbar} style={ navbarhide ? { display: 'none' } : {}} >
            {navbarlinks.map((navbarlink, index)=> 
                <NavLink
                 key={'kkl'+index}
                 className={(({isActive}) => isActive ? classes.navbarlinkactive : classes.navbarlink)} to={Object.keys(navbarlink)[0]}>
                    {Object.values(navbarlink)[0]}
                 </NavLink>
            )}
        </div>
    );
}

export default Navbar;