import { createSlice } from "@reduxjs/toolkit";

const initvalue  = {
    'to': null,
    'init': true
}

const redirectSlice = createSlice({
    name: 'redirect',
    initialState: initvalue,
    reducers: {
        updateredirectto(state, action)
        {
            state.to = action.payload;
        },
        updateredirectinit(state, action)
        {
            state.init = action.payload;
        }
    }
});

export default redirectSlice.reducer;

export const redirectsliceactions = redirectSlice.actions;