import { useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import Input from "../components/Input";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Table from "../components/Table";
import React from "react";
import Details from "../components/Details";

const Monthbills = () => {
    let monthref = useRef();
    let [month, setMonth] = useState('');
    let [year, setYear] = useState('');
    let [bills, setBills] = useState([]);
    const [dateMax, setDateMax] = useState('');

    const [total, setTotal] = useState('');
    const [credit, setCredit] = useState('');
    const [p, setP] = useState(undefined);
    const [frommedicine, setFrommedicine] = useState('');
    const [creditspaid, setCreditspaid] = useState('');

    const monthchangehandler = () => {
        let date = monthref.current.value.trim();
        const newdate = new Date(date + "-01");

        setMonth(newdate.getMonth() + 1);
        setYear(newdate.getFullYear())
    }

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    
    const TranformBillDate = (date) => {
        return (
          `${date.substring(8, 10)} (${date.substring(11, 16)})`
        );
      };

      const BillTypee = (billid) => {
        if (billid.includes("CRPY")) {
          return (
            <div
              style={{
                width: '70px',
                background: "#00c92b",
                padding: "2px 5px",
                borderRadius: "3px",
              }}
            >
              Credit Payment
            </div>
          );
        } else if (billid.includes("CASH")) {
          return (
            <div
              style={{
                width: '70px',
                background: "#ffeb3b",
                padding: "2px 5px",
                borderRadius: "3px",
              }}
            >
              Cash/Old Credit
            </div>
          );
        } else {
          return <div>Bill</div>;
        }
      };

      useEffect(() => {
        var now = new Date();
        if(now.getMonth() < 9)
        {
            setDateMax(`${now.getFullYear()}-0${now.getMonth() + 1}`);
        }
        else
        {
            setDateMax(`${now.getFullYear()}-${now.getMonth() + 1}`);
        }
    },[]);

    const getMonthBills = () => {
        let requestbody = {};
        requestbody['month'] = month;
        requestbody['year'] = year;
        usHttp2(appvariables.apiurl + 'bill/getmonthbills/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) =>{
          setBills(json['bills']);
          setP(json.profit);
        })
    }

    const tablefields = [
        {'name': 'Type' , 'key': 'billid', transformer: BillTypee, cs: {width: '80px'}},
        {'name': 'Customer', 'key': 'customer', cs:{ width: '150px' }},
        {'name':'Date', 'key': 'createddatetimestamp', transformer: TranformBillDate, cs: {width: '60px'}},
        {'name':'Products', 'key': 'products', cs: {width: '580px'}},
        {'name':'Total', 'key': 'totalfinaldecided',cs:{ width: '60px'}},
        {name: 'Recieved', key: 'tfr', cs:{ width: '60px' }, summary: 1},
        {'name':'Credit', 'key': 'credit',cs:{ width: '60px' }, summary: 1},
    ];

    useEffect(() => {
        let total = 0;
        let balance = 0;
        let medicine = 0;
        let creditpaid = 0;
        for(let i=0; i< bills.length; i++)
        {
            // if(bills[i]['billid'].substring(0,4) === 'BILL' || bills[i]['billid'].substring(0,4) === 'CASH')
            // {
            //   total = total + parseInt(bills[i]['totalfinaldecided']);
            // }
            // if(bills[i]['billid'].substring(0,4) === 'BILL')
            // {
            //   balance = balance + bills[i]['credit'];
            //   medicine = medicine + (parseInt(bills[i]['totalfinaldecided']) - parseInt(bills[i]['credit'])); 
            // }
            if(bills[i]['billid'].substring(0,4) === 'BILL')
            {
              total = total + parseInt(bills[i]['totalfinaldecided']);
              balance = balance + parseInt(bills[i]['credit']);
              medicine = medicine + parseInt(bills[i]['tfr']);
            }
            if(bills[i]['billid'].substring(0,4) === 'CASH')
            {
              balance = balance + parseInt(bills[i]['credit']);
            }
            if(bills[i]['billid'].substring(0,4) === 'CRPY')
            {
              creditpaid = creditpaid + parseInt(bills[i]['totalfinaldecided']);
            }
        }
        setTotal(total);
        setCredit(balance);
        setFrommedicine(medicine);
        setCreditspaid(creditpaid);
    },[bills]);


    return(
        <div style={{width: '950px'}}>
            <div style={{width: '600px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '10px auto'}} >
                <div style={{width: '350px'}} >
                    <Input type='month' name='month' displayname='Select Month' inputboxstyle='normal' max={dateMax} refvar={monthref} changehandler={monthchangehandler} />
                </div>
                <Button disabled={month === '' || year === ''} cs={{backgroundColor: (month === '' || year === '') && 'rgba(0,0,0,0.6)'}} clickhandler={getMonthBills} >Get Bills</Button>
            </div>
            {bills.length !== 0 && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h2>{`${monthNames[month - 1]}, ${year}`}</h2>
                <Table data={bills} tablefields={tablefields} decider={'credit'} summary={true} rowstyling={{ backgroundColor: "rgb(255 73 73)"}} />
            </div>}
            {bills.length !== 0 && <Details data={[{'name': 'Total Medicines Sold', 'value': total},{'name':'Credit Given', 'value': credit}, {name: 'Counter (Only Medicine)', value: frommedicine}, {name: 'Credits Paid (+)', value: creditspaid }, {name: 'Counter (including credits paid)', value: (frommedicine + creditspaid )}]}  />}
            {
                p !== undefined ? <p style={{ color: '#ffffff' }} >Profit for the bill is: {p}</p> : <></>
            }
        </div>
    )
}

export default Monthbills;