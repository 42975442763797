import React, { useState, useRef, useEffect } from "react";
import CustomForm from "../components/CustomForm";
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Table from "../components/Table";
import Button from "../components/Button";
import { useRouteLoaderData } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from '../store/loading-slice';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const Addstock = (props) => {

    // name, package, company, batchid, quantity, price, eyear, emonth, edate

    const [name, setName] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const [batchid, setBatchid] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [eyear, setEyear] = useState('');
    const [emonth, setEmonth] = useState('');
    const [edate, setEdate] = useState('');
    const [emax, setEmax] = useState();

    const [producterror, setProducterror] = useState('');
    const [batchiderror, setBatchiderror] = useState('');
    const [quantityerror, setQuantityerror] = useState('');
    const [priceerror, setPriceerror] = useState('');
    const [expdateerror, setExpdateerror] = useState('');

    const productref = useRef();
    const batchidref = useRef();
    const quantityref = useRef();
    const priceref = useRef();
    const expirydateref = useRef();

    const masterdispatcher = useDispatch();

    const { productstrings }  = useRouteLoaderData('stocklayoutloader');

    const [productstringset, setProductstringset] = useState(new Set());

    useEffect(() => {
        const kk = new Set(productstrings);
        setProductstringset(kk);
    }, [productstrings]);

    const productinputchange = () => {
        var product_var = (productref.current.value).split('---');
        if(product_var.length === 3)
        {
            setName(product_var[0]);
            setPackagee(product_var[1]);
            setCompany(product_var[2]);
        }
        else
        {
            setName('');
            setPackagee('');
            setCompany('');
        }
    };
    const batchidinputchange = () => {
        setBatchid(batchidref.current.value.trim());
    };
    const quantityinputchange = () => {
        setQuantity(parseInt(quantityref.current.value.trim()));
    };
    const priceinputchange = () => {
        setPrice(parseInt(priceref.current.value.trim()));
    };
    const expirydateinputchange = () => {
        const expirydatee = expirydateref.current.value.trim();

        const newexdate = new Date(expirydatee + "-01");

        setEyear(newexdate.getFullYear());
        setEmonth(newexdate.getMonth()+1);
        setEdate(newexdate.getDate());
    };

    const submithandlerenabler = () => {
        return producterror.length === 0 && name.length > 2 && packagee.length > 2 && company.length > 3 && batchid.length > 2 && batchid.length < 21 && quantity > 0 && price > 0 && eyear !== '' && emonth !== '' && edate !== ''
    }

    useEffect(() => {
        var now = new Date();
        if (now.getMonth() == 11) {
            setEmax(`${now.getFullYear() + 1}-01`);
        } else {
            if(now.getMonth() + 1 < 8)
            {
                setEmax(`${now.getFullYear()}-0${now.getMonth() + 2}`);
            }
            else
            {
                setEmax(`${now.getFullYear()}-${now.getMonth() + 2}`);
            }
        }
    },[]);

    const addstocktoserver = (e) =>{
        var requestbody = {};
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        e.preventDefault();
        var stockdata = {
            'name': name,
            'package': packagee,
            'company': company,
            'batchid': batchid.toLowerCase(),
            'quantity': String(quantity),
            'price': String(price),
            'eyear': String(eyear),
            'emonth': String(emonth),
            'edate': String(edate)
        }
        requestbody['stock'] = stockdata;
        usHttp2(appvariables.apiurl+'stock/addone/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => 
        {
            masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
        setName('');
        setPackagee('');
        setCompany('');
        setBatchid('');
        setQuantity('');
        setPrice('');
        setEyear('');
        setEmonth('');
        setEdate('');
        productref.current.focus();
        productref.current.value = ''
        batchidref.current.value = ''
        quantityref.current.value = ''
        priceref.current.value = ''
        expirydateref.current.value = ''
        setExpdateerror('');
    }

    const inputblur = (inp) => {
        if( inp === 'product'){ if(productref.current.value.trim() === '')
        {
            setProducterror('Select Product');
        }
        else if(!(productstringset.has(productref.current.value)))
        {
            setProducterror('Select Product from options if the required product is not available add that in Add Product Page');
        }
        else{
            setProducterror('');
        }}
        if( inp === 'batchid'){ if(batchid.length < 3)
        {
            setBatchiderror('Batch Id length should be minimum 3');
            batchidref.current.value = batchid;
        }
        else if(batchid.length > 20)
        {
            setBatchiderror("Batch Id length can't be more than 20");
        }
        else{
            setBatchiderror('');
        }}
        if( inp === 'quantity'){ if(quantity < 1)
        {
            setQuantityerror('Atleast 1 stock is needed');
        }
        else{
            setQuantityerror('');
        }}
        if( inp === 'price')
        {
            if(price < 1)
            {
                setPriceerror('Price cannot be less than 1');
            }
            else{
                setPriceerror('');
            }
        } 
        if( inp === 'expirydate'){
            if(expirydateref.current.value.trim() === '')
            {
                setExpdateerror('Select Expiry Date');
            }
            else{
                setExpdateerror('');
            }
        }
    }

    const inputs = [
        {
            id:'addstockproduct',
            displayname: 'Product',
            name: 'product',
            type: 'text',
            refvar: productref,
            error: producterror,
            changehandler: productinputchange,
            datalist: productstrings,
            blurhandler: () => inputblur('product'),
        },
        {
            id: 'addstockbatchid',
            displayname: 'Batch Id',
            name:'batchid',
            type: 'text',
            refvar: batchidref,
            error: batchiderror,
            changehandler: batchidinputchange,
            blurhandler: () => inputblur('batchid'),
        },
        {
            id: 'addstockquant',
            displayname: 'Quantity',
            name:'quantity',
            type: 'number',
            refvar: quantityref,
            error: quantityerror,
            changehandler: quantityinputchange,
            blurhandler: () => inputblur('quantity'),
        },
        {
            id: 'addstockprice',
            displayname: 'Price',
            name: 'price',
            type: 'number',
            refvar: priceref,
            error: priceerror,
            changehandler: priceinputchange,
            blurhandler: () => inputblur('price'),
        },
        {
            id: 'addstockex',
            displayname: 'Expiry Date',
            name:'expirydate',
            type: 'month',
            min: emax,
            refvar: expirydateref,
            error: expdateerror,
            changehandler: expirydateinputchange,
            blurhandler: () => inputblur('expirydate'),
        }
    ];


    return(
        <div>
            <CustomForm resetdisabled={true} submitdisabled={!submithandlerenabler()} inputs={inputs} inputboxstyle={'normal'} submitHandler={addstocktoserver} />
        </div>
    );
}

const Addstocks = (props) => {
    const [name, setName] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const [batchid, setBatchid] = useState('');
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [eyear, setEyear] = useState('');
    const [emonth, setEmonth] = useState('');
    const [edate, setEdate] = useState('');
    const [stocks, setStocks] = useState([]);
    const [emax, setEmax] = useState();
    const [batchiddl, setBatchiddl] = useState([]);

    const [producterror, setProducterror] = useState('');
    const [batchiderror, setBatchiderror] = useState('');
    const [quantityerror, setQuantityerror] = useState('');
    const [priceerror, setPriceerror] = useState('');
    const [expdateerror, setExpdateerror] = useState('');

    const productref = useRef();
    const batchidref = useRef();
    const quantityref = useRef();
    const priceref = useRef();
    const expirydateref = useRef();

    const masterdispatcher = useDispatch();

    useEffect(() => {
        var now = new Date();
        if (now.getMonth() == 11) {
            setEmax(`${now.getFullYear() + 1}-01`);
        } else {
            if(now.getMonth() + 1 < 8)
            {
                setEmax(`${now.getFullYear()}-0${now.getMonth() + 2}`);
            }
            else
            {
                setEmax(`${now.getFullYear()}-${now.getMonth() + 2}`);
            }
        }
    },[]);


    const { productstrings } = useRouteLoaderData('stocklayoutloader');

    const [productstringset, setProductstringset] = useState(new Set());

    const { productbatchddict } = useRouteLoaderData('stocklayoutloader');

    useEffect(() => {
        const kk = new Set(productstrings);
        setProductstringset(kk);
    }, [productstrings]);

    const submithandlerenabler = () => {
        return name.length > 2 && packagee.length > 2 && company.length > 3 && batchid.length > 2 && batchid.length < 21 && quantity > 0 && price > 0 && eyear !== '' && emonth !== '' && edate !== ''
    }

    const productinputchange = () => {
        var product_var = (productref.current.value).split('---');
        if(product_var.length === 3)
        {
            setName(product_var[0]);
            setPackagee(product_var[1]);
            setCompany(product_var[2]);
        }
        else
        {
            setName('');
            setPackagee('');
            setCompany('');
        }
    };
    const batchidinputchange = () => {
        setBatchid(batchidref.current.value.trim());
    };
    const quantityinputchange = () => {
        setQuantity(parseInt(quantityref.current.value.trim()));
    };
    const priceinputchange = () => {
        setPrice(parseInt(priceref.current.value.trim()));
    };
    const expirydateinputchange = () => {
        const expirydatee = expirydateref.current.value.trim();

        const newexdate = new Date(expirydatee + "-01");

        setEyear(newexdate.getFullYear());
        setEmonth(newexdate.getMonth()+1);
        setEdate(newexdate.getDate());
    };

    const addstocktolocal = (e) =>{
        e.preventDefault();
        let stockdata = {
            'name': name,
            'package': packagee,
            'company': company,
            'batchid': batchid.toLowerCase(),
            'quantity': String(quantity),
            'price': String(price),
            'eyear': String(eyear),
            'emonth': String(emonth),
            'edate': String(edate),
            'rowkey': uuidv4()
        }
        let temp_stocks = [...stocks];
        temp_stocks.push(stockdata);
        setStocks(temp_stocks);
        setName('');
        setPackagee('');
        setCompany('');
        setBatchid('');
        setQuantity('');
        setPrice('');
        setEyear('');
        setEmonth('');
        setEdate('');
        setBatchiddl([]);
        productref.current.focus();
        productref.current.value = ''
        batchidref.current.value = ''
        quantityref.current.value = ''
        priceref.current.value = ''
        expirydateref.current.value = ''
        setExpdateerror('');
    }

    const addstockstoserver = (e) => {
        var requestbody = {};
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        requestbody['stocks'] = stocks;
        usHttp2(appvariables.apiurl+'stock/addmore/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => 
        {
            masterdispatcher(notificationsliceactions.updatenotification({'message': 'Successfully added Stock', 'status': 'Success'}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
        setStocks([]);
    }

    const getDetailsforProductandBatchId = () => {
        let requestbody = {};
        requestbody['name'] = name;
        requestbody['package'] = packagee;
        requestbody['company'] = company;
        requestbody['batchid'] = batchid;
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        usHttp2(appvariables.apiurl + 'stock/getstockdetailsbatchid/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => {
            setEdate(1);
            setEmonth(json['emonth']);
            setEyear(json['eyear']);
            setPrice(json['price']);
            priceref.current.value = json['price'];
            expirydateref.current.value = json['eyear'] + '-' + json['emonth'];
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
        })
    }

    const inputblur = (inp) => {
        if( inp === 'product'){  if(productref.current.value.trim() === '')
        {
            setProducterror('Select Product');
            setBatchiddl([]);
        }
        else if(!(productstringset.has(productref.current.value)))
        {
            setProducterror('Select Product from options if the required product is not available add that in Add Product Page');
            setBatchiddl([]);
        }
        else{
            setProducterror('');
            setBatchiddl(productbatchddict[productref.current.value]);
        }}
        if( inp === 'batchid'){ if(batchid.length < 3)
        {
            setBatchiderror('Batch Id length should be minimum 3');
            batchidref.current.value = batchid;
        }
        else if(batchid.length > 20)
        {
            setBatchiderror("Batch Id length can't be more than 20");
        }
        else{
            setBatchiderror('');
            if(name.length !== 0 &&  packagee.length !== 0 && company.length !== 0)
            {
                if(productbatchddict[productref.current.value].includes(batchid))
                {
                    getDetailsforProductandBatchId()
                }
            }
        }}
        if( inp === 'quantity'){ if(quantity < 1)
        {
            setQuantityerror('Atleast 1 stock is needed');
        }
        else{
            setQuantityerror('');
        }}
        if( inp === 'price')
        {
            if(price < 1)
            {
                setPriceerror('Price cannot be less than 1');
            }
            else{
                setPriceerror('');
            }
        } 
        if( inp === 'expirydate'){
            if(expirydateref.current.value.trim() === '')
            {
                setExpdateerror('Select Expiry Date');
            }
            else{
                setExpdateerror('');
            }
        }
    }

    const inputs = [
        {
            id:'addstockproduct',
            displayname: 'Product',
            name: 'product',
            type: 'text',
            refvar: productref,
            error: producterror,
            changehandler: productinputchange,
            datalist: productstrings,
            blurhandler: () => inputblur('product'),
        },
        {
            id: 'addstockbatchid',
            displayname: 'Batch Id',
            name:'batchid',
            type: 'text',
            refvar: batchidref,
            error: batchiderror,
            datalist: batchiddl,
            changehandler: batchidinputchange,
            blurhandler: () => inputblur('batchid'),
        },
        {
            id: 'addstockquant',
            displayname: 'Quantity',
            name:'quantity',
            type: 'number',
            refvar: quantityref,
            error: quantityerror,
            changehandler: quantityinputchange,
            blurhandler: () => inputblur('quantity'),
        },
        {
            id: 'addstockprice',
            displayname: 'Price',
            name: 'price',
            type: 'number',
            refvar: priceref,
            error: priceerror,
            changehandler: priceinputchange,
            blurhandler: () => inputblur('price'),
        },
        {
            id: 'addstockex',
            displayname: 'Expiry Date',
            name:'expirydate',
            type: 'month',
            min: emax,
            refvar: expirydateref,
            error: expdateerror,
            changehandler: expirydateinputchange,
            blurhandler: () => inputblur('expirydate'),
        }
    ];

    const deleteRow = (rowkeyy) => {
        var temp_rows = [...stocks];
        for(var i=0; i < temp_rows.length; i++)
        {
            if(temp_rows[i].rowkey === rowkeyy)
            {
                temp_rows.splice(i, 1);
                break;
            }
        }
        setStocks(temp_rows);
    }

    const tablefields = [
        {'name': 'Name', 'key':'name'},
        {'name': 'Package', 'key':'package'},
        {'name': 'Company', 'key':'company'},
        {'name': 'Batch Id', 'key':'batchid'},
        {'name': 'Quantity', 'key':'quantity'},
        {'name': 'Price', 'key':'price'},
        {'name': 'ExpiryYear', 'key':'eyear'},
        {'name': 'ExpiryMonth', 'key':'emonth'},
        {'name': 'ExpiryDate', 'key':'edate'},
        {'name': 'Delete', 'key': 'rowkey', transformerr: deleteRow, text: 'X', cs: { width: "70px" }},
    ]

    return(
        <div  style={{display: 'flex', flexDirection:'column' ,alignItems: 'center', width: '100%'}}>
            <CustomForm submitdisabled={!submithandlerenabler()} inputs={inputs} inputboxstyle={'normal'} submitHandler={addstocktolocal} resetdisabled={true} />
            <Table data={stocks} tablefields={tablefields} />
            <Button clickhandler={addstockstoserver} disabled={stocks.length < 1} cs={{backgroundColor: stocks.length < 1 && 'rgba(0,0,0,0.6)', borderColor: stocks.length < 1 && 'rgba(0,0,0,0.6)'}} >Add Products</Button>
        </div>
    );
}

export { Addstock, Addstocks };