// // Home
// const appvariables = {
//     'apiurl': 'http://192.168.1.4:8000/shopbilling/',
//     'weburl': 'http://192.168.1.4:1234/',
//     'url': '192.168.1.4'
// }

// // Shop
// const appvariables = {
//     'apiurl': 'http://192.168.0.110:8000/shopbilling/',
//     'weburl': 'http://192.168.0.110:1234/',
//     'url': '192.168.0.110'
// }

// Main
const appvariables = {
    'apiurl': 'https://api.sairamveterinary.com/shopbilling/',
    'weburl': 'https://shopbilling.sairamveterinary.com/',
    'url': 'sairamveterinary.com',
}

export default appvariables;