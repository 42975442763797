import classes from './Dropdown.module.css';
import { useState, useEffect } from 'react';

const Dropdown = (props) => {

    const [dropdownbox, setDropdownbox] = useState();
    const [isinitial, setIsinitial] = useState(1);

    useEffect(() => {
        setIsinitial(1);
    },[props.options])

    const [dropdownclass, setDropdownclass] = useState('normal');

    if(isinitial)
    {
        if(props.dropdownstyle === 'normal')
        {
            setDropdownbox(
                    <>
                        <label  className={classes.label} htmlFor={props.id}>{props.displayname}</label>
                        <select className={classes.dropdown} name={props.name} id={props.id} onChange={props.changehandler}>
                            <option value={""} >Select Option</option>
                            {props.options ? props.options.map((option, index) => {
                                return <option value={option}>{option}</option>
                            }): null}
                        </select>
                    </>
            )
        }
        if(props.dropdownstyle === 'compact')
        {
            setDropdownbox(
            <>
                <select ref={props.refvar} className={classes.dropdown} name={props.name} id={props.id} onChange={props.changehandler}>
                    <option  value={""}>Select {props.displayname}</option>
                    {props.options ? props.options.map((option, index) => {
                        return <option value={option}>{option}</option>
                    }): null}
                </select>
            </>)
        }
        if(props.dropdownstyle === 'compactwithlabels')
        {
            setDropdownbox(
                    <>
                        <label  className={classes.label} htmlFor={props.id}>{props.displayname}</label>
                        <select ref={props.refvar} className={classes.dropdown} name={props.name} id={props.id} onChange={props.changehandler} defaultValue={''} >
                            <option value={""} >Select Option</option>
                            {props.options ? props.options.map((option, index) => {
                                return <option value={option}>{option}</option>
                            }): null}
                        </select>
                    </>
            )
        }
        setIsinitial(0);
        if(props.dropdownstyle === 'compact')
        {
            setDropdownclass(classes.compact);
        }
        if(props.dropdownstyle === 'compactwithlabels')
        {
            setDropdownclass(classes.compactwithlabels);
        }
        if(props.dropdownstyle === 'normal')
        {
            setDropdownclass(classes.normal);
        }
    }




    return(
        <div className={dropdownclass}>
            {dropdownbox}
        </div>
    )
}

export default Dropdown