import { Link } from "react-router-dom";
import classes from './homepage.module.css';
import { useSelector } from "react-redux";
import { usHttp } from "../../custom-hooks/use-http";
import appvariables from "../../appvariables";

const Stockhomepage = () => {
    var stocksectionlinks = useSelector(state => state.budata.sectionlinks.stock);

    if(stocksectionlinks === undefined)
    {
        stocksectionlinks = []
    }

    return(
        <div className={classes.homepage}>
            {stocksectionlinks.map((stocksectionlink, index)=> 
                <Link
                 key={'kkl'+index}
                 to={Object.keys(stocksectionlink)[0]}>
                    {Object.values(stocksectionlink)[0]}
                 </Link>
            )}
        </div>
    )
}

const loadStockLayout = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'product/getstrings/','POST',{})
    if(responsedata != null)
    {
        return {
            'productstrings': responsedata['productstrings'],
            'productbatchddict': responsedata['productbatchddict'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
}

const loadAllStocksData = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'stock/getall/','POST',{})
    if(responsedata != null)
    {
        return {
            'stocks': responsedata['stocks'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
}

export { loadStockLayout, loadAllStocksData };


export default Stockhomepage;