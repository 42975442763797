import { useLoaderData, useNavigate } from "react-router-dom";
import CustomForm from "../components/CustomForm";
import React, { useState, useRef, useEffect } from "react";
import Table from "../components/Table";
import Details from "../components/Details";
import Dropdown from "../components/Dropdown";
import { usHttp, usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import Button from "../components/Button";
import AddStocktobill from "./customcomponents/AddStocktobill";
import { useDispatch, useSelector } from 'react-redux';
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";
import { budatasliceactions } from "../store/budata-slice";

const Createbill = (props) => {
    const masterdispatcher = useDispatch();
    const navigate = useNavigate();
    const { vilcust } = useLoaderData();
    const { stocksdata } = useLoaderData();
    const cvillages = vilcust.villages;
    const vilcustomers = vilcust.vilcustobj;
    const [remproducts, setRemproducts] = useState([]);

    const customervillageref = useRef();
    const customernameref = useRef();

    const [ villages, setVillages] = useState(cvillages);
    const [customername, setCustomername] = useState('');
    const [customervillage, setCustomervillage] = useState('');
    const [customernumber, setCustomernumber] = useState('');
    const [ccredit, setCcredit] = useState('');

    const [name, setName] = useState('');
    const [packagee, setPackagee] = useState('');
    const [company, setCompany] = useState('');
    const [batchid, setBatchid] = useState('');
    const [price, setPrice] = useState(0);
    const [expirydate, setExpiryDate] = useState('');
    const [rowkey, setRowkey] = useState('');

    const [stock, setStock] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [quantityerror, setQuantityerror] = useState('');
    const [individualPrice , setIndividualPrice] = useState(0);
    const [indipriceerror, setIndipriceerror] = useState('');
    const [billrowdetails, setBillrowdetails] = useState([]);
    const [billfinaldetails, setBillfinaldetails] = useState({});
    const [totalfinaldecided, setTotalfinaldecided] = useState(0);
    const [totalrecieved, setTotalrecieved] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [remarksneeded, setRemarksneeded] = useState(false);

    const [taskDays, setTaskDays] = useState(0);
    const [taskMessage, setTaskMessage] = useState('');

    const totalfinaldecidedinputref = useRef();
    const totalrecievedinputref = useRef();
    const remarksref = useRef();
    const checkeddref = useRef();
    const redirectref = useRef();
    const taskdaysref = useRef();
    const taskmessageref = useRef();

    const bupermissions = useSelector(state => state.budata.permissions);

    const totalfinaldecidedchange = () => {
        setTotalfinaldecided(parseInt(totalfinaldecidedinputref.current.value));
        var inputss = [...inputsforbilling];
        inputss[1].max = totalfinaldecidedinputref.current.value;
        totalrecievedinputref.current.value = totalfinaldecidedinputref.current.value;
        setTotalrecieved(totalfinaldecidedinputref.current.value);
        setInputsforbilling(inputss);
    }

    const totalfinaldecidedblur = () => {
        if(totalfinaldecided > billfinaldetails['totalafterindividualdiscount'])
        {
            var kk = [...inputsforbilling];
            kk[0].error = 'Final Decided should be less than total from individual discounts';
            setInputsforbilling(kk);
        }
        else{
            var kk = [...inputsforbilling];
            kk[0].error = '';
            setInputsforbilling(kk);
        }
    }

    useEffect(() => {
        if(customernumber != '')
        {
            masterdispatcher(budatasliceactions.updatenavbarhide(true));
        }
        else
        {
            masterdispatcher(budatasliceactions.updatenavbarhide(false));
        }
    }, [customernumber]);

    
    const totalrecievedchange = () => {
        setTotalrecieved(parseInt(totalrecievedinputref.current.value));
    }
    
    const totalrecievedblur = () => {
        if(totalrecieved > totalfinaldecided)
        {
            var kk = [...inputsforbilling];
            kk[1].error = 'Total Recieved should be less than or equal to Total Final Decided';
            setInputsforbilling(kk);
        }
        else{
            var kk = [...inputsforbilling];
            kk[1].error = '';
            setInputsforbilling(kk);
        }
    }

    const remarkschangehandler = () => {
        setRemarks(remarksref.current.value.trim());
    }
    
    useEffect(() => {
        var kk = [...inputsforbilling];
        kk[0].blurhandler = totalfinaldecidedblur;
        kk[1].blurhandler = totalrecievedblur;
        setInputsforbilling(kk);
    },[totalfinaldecided, totalrecieved, billfinaldetails]);

    const [inputsforbilling, setInputsforbilling] = useState([
        {
            type: 'number',
            id: 'tftid',
            refvar: totalfinaldecidedinputref,
            changehandler:totalfinaldecidedchange,
            blurhandler: totalfinaldecidedblur,
            displayname: 'Total Final Decided',
            name: 'totalfinaldecided',
            max: 0,
            error: ''
        },
        {
            type: 'number',
            id: 'trid',
            refvar: totalrecievedinputref,
            changehandler: totalrecievedchange,
            blurhandler: totalrecievedblur,
            displayname: 'Total Recieved',
            name: 'totalrecieved',
            max: 0,
            error: ''
        },
        {
            type: 'textbox',
            id: 'rmidd',
            refvar: remarksref,
            changehandler: remarkschangehandler,
            displayname: 'Remarks',
            name: 'remarks'
        },
        {
            type: 'checkbox',
            id: 'bcid',
            refvar: checkeddref,
            changehandler: (e) => setCheckedd(e.target.checked),
            displayname: 'Checked',
            name: 'checked',
        },
        {
            type: 'checkbox',
            id: 'bcid231',
            refvar: redirectref,
            changehandler: (e) => setRedirect(e.target.checked),
            displayname: 'Show Bill After Creation',
            name: 'redirect',
        },
        {
            type: 'number',
            id: 'taskdatee243',
            min: 1,
            changehandler: (e) => setTaskDays(e.target.value),
            displayname: 'Remind After (in Days)',
            name: 'taskdays',
            refvar: taskdaysref,
            show: ['admin', 'owner'].includes(bupermissions),
        },
        {
            type: 'text',
            id: 'taskmessage243',
            changehandler: (e) => setTaskMessage(e.target.value.trim()),
            displayname: 'Task Message',
            name: 'taskmessage',
            refvar: taskmessageref,
            show: ['admin', 'owner'].includes(bupermissions),
        }
    ]);

    useEffect(() => {
        if(customername === 'creditname' && customervillage === 'creditvillage')
        {
            setRemarksneeded(true);
        }
        else
        {
            setRemarksneeded(false);
        }
        if(customername === 'Others' && customervillage === 'Others')
        {
            let inputss = [...inputsforbilling];
            inputss[1].readonly = true;
            setInputsforbilling(inputss);
        }
        else{
            let inputss = [...inputsforbilling];
            inputss[1].readonly = false;
            setInputsforbilling(inputss);
        }
    },[customername, customervillage]);

    useEffect(() => {
        if(customername === 'Others' && customervillage === 'Others')
        {
            if(totalfinaldecided >= 600)
            {
                setRemarksneeded(true);
            }
            else if(totalfinaldecided >= 200 && !(['admin', 'owner'].includes(bupermissions)))
            {
                setRemarksneeded(true);
            }
            else
            {
                setRemarksneeded(false);
            }
        }
    },[totalfinaldecided, customername, customervillage]);


    const [billrows, setBillRows] = useState([]);

    const comparebillrow = (br, br1) => {
        const check1 = br.name == br1.name;
        const check2 = br.package == br1.package;
        const check3 = br.company == br1.company;
        const check4 = br.batchid == br1.batchid;
        const check5 = br.price == br1.price;
        const check6 = br.expirydate == br1.expirydate;

        return check1 && check2 && check3 && check4 && check5 && check6;
    }

    const addbillrowtolocal = (e) => {
        e.preventDefault();
        var billrow = {
            'rowkey': rowkey,
            'name': name,
            'package': packagee,
            'company': company,
            'batchid': batchid,
            'price': parseInt(price),
            'expirydate': expirydate,
            'quantity': parseInt(quantity),
            'individualpriceenteredbybuser': parseInt(individualPrice),
            'rowtotal': parseInt(individualPrice) * parseInt(quantity)
        };
        var temp_billrows = [...billrows];
        for(var i=0; i< temp_billrows.length; i++)
        {
            if(comparebillrow(billrow, temp_billrows[i]) == true){
                temp_billrows.splice(i, 1);
                break;
            }
        }
        temp_billrows.push(billrow);
        setBillRows(temp_billrows);
        setStock('');
        setName('');
        setPackagee('');
        setCompany('');
        setBatchid('');
        setPrice(0);
        setExpiryDate('');
        setStock('');
        setQuantity(0);
        setIndividualPrice(0);
        quantityref.current.value = "";
        individualpriceref.current.value = "";
        setBillrowdetails([]);
        setCheckedd(false);
        checkeddref.current.checked = false;
        toggleraddertoogler();
    }

    useEffect(() => {
        if(stock !== '')
        {
            quantityref.current.focus();
        }
    },[stock]);

    const deleteBillRow = (rowkeyy) => {
        var temp_billrows = [...billrows];
        for(var i=0; i < temp_billrows.length; i++)
        {
            if(temp_billrows[i].rowkey === rowkeyy)
            {
                temp_billrows.splice(i, 1);
                break;
            }
        }
        setBillRows(temp_billrows);
    }



    useEffect(() => {
        var totalfrommrp = 0;
        var totalafterindidis = 0;
        for(var i=0; i< billrows.length; i++)
        {
            totalfrommrp = totalfrommrp + billrows[i].price * billrows[i].quantity;
            totalafterindidis = totalafterindidis + billrows[i]['individualpriceenteredbybuser'] * billrows[i].quantity;
        }
        setBillfinaldetails({
            'totalfrommrp': totalfrommrp,
            'totalafterindividualdiscount': totalafterindidis
        });
        var inputss = [...inputsforbilling];
        inputss[0].max = totalafterindidis;
        inputss[1].max = totalafterindidis;
        setInputsforbilling(inputss);
        totalfinaldecidedinputref.current.value = totalafterindidis;
        totalrecievedinputref.current.value = totalafterindidis;
        setTotalfinaldecided(totalafterindidis);
        setTotalrecieved(totalafterindidis);
    },[billrows]);
    
    const quantityref = useRef();
    const individualpriceref = useRef();

    const quantityinputchange = () => {
        setQuantity(quantityref.current.value);
    }

    const quantityinputblur = () => {
        if(quantity === '' || quantity == 0)
        {
            setQuantityerror('Quantity Should be greater than one');
        }
        else{
            setQuantityerror('');
        }
    }

    const individualpriceinputchange = () => {
        setIndividualPrice(individualpriceref.current.value);
    }

    const individualpriceinputblur = () => {
        if(individualPrice > price || individualPrice === '' || individualPrice == 0)
        {
            setIndipriceerror(`Price Should be greater than 0 and less than ${price}`)
        }
        else{
            setIndipriceerror('');
        }
    }

    const selectStock = (strr) => {
        const kk = strr.split('---');
        setName(kk[0]);
        setPackagee(kk[1]);
        setCompany(kk[2]);
        setBatchid(kk[3]);
        setPrice(parseInt(kk[4]));
        setExpiryDate(kk[5]);
        setRowkey(strr);
        setQuantity(0);
        setIndividualPrice(0);
        setQuantityerror('');
        setIndipriceerror('');
        if(name !== '')
        {
            individualpriceref.current.value = '';
            quantityref.current.value = '';
        }
        setStock(kk);
    }

    useEffect(() => {
        setBillrowdetails([
            {'name': 'Name', 'value': name},
            {'name': 'Package', 'value': packagee},
            {'name': 'Company', 'value': company},
            {'name': 'Price', 'value': price},
            {'name': 'Batch Id', 'value': batchid},
            {'name': 'Expiry Date', 'value': expirydate},
        ])
        inputs[1].max = price;
    },[stock]);

    const inputs = [
        {
            id: 'createbillquantity',
            displayname: 'Quantity',
            name:'quantity',
            type: 'number',
            refvar: quantityref,
            changehandler: quantityinputchange,
            blurhandler: quantityinputblur,
            error: quantityerror
        },
        {
            id: 'createbillindirowprice',
            displayname: 'Individual Price',
            name:'indiprice',
            type: 'number',
            max: price,
            refvar: individualpriceref,
            changehandler: individualpriceinputchange,
            blurhandler: individualpriceinputblur,
            error: indipriceerror
        }
    ];

    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const tablefields2 = [
        {'name': 'Name', 'key': 'name'},
        {'name': 'Package', 'key': 'package'},
        {'name': 'Batch Id', 'key':'batchid'},
        {'name': 'Expiry Date', 'key':'expirydate', transformer: TranformDate},
        {'name': 'Price', 'key': 'price', cs: { width: "70px" }},
        {'name': 'Our Price', 'key': 'individualpriceenteredbybuser', cs: { width: "100px" }},
        {'name': 'Quantity', 'key':'quantity', cs: { width: "70px" }},
        {'name': 'Total', key: 'rowtotal', cs: { width: "70px" }},
        {'name': 'Delete', 'key': 'rowkey', transformerr: deleteBillRow, text: 'X', cs: { width: "70px" }},
    ]

    const [vilcustre, setVilcustre] = useState([]);

    const villagechangehandler = (e) => {
        setCustomervillage(e.target.value);
        setCustomername('');
        customernameref.current.value = '';
    }

    const custnamechangehandler = (e) => {
        setCustomername(e.target.value);
    }

    const billfinaldetailsc = [
        {'name': 'Total From MRPs', 'value': billfinaldetails['totalfrommrp']},
        {'name': 'Total after Individual Discount', 'value': billfinaldetails['totalafterindividualdiscount']},
        {'name': 'Discount', 'value': billfinaldetails['totalfrommrp']-totalfinaldecided }
    ]

    useEffect(() => {
        setVilcustre(vilcustomers[customervillage]);
    },[customervillage]);


    const addbilltoserver = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        var requestbody = {};
        requestbody['customername'] = customername;
        requestbody['customervillage'] = customervillage;
        requestbody['stockdetailjson'] = billrows;
        requestbody['totalfrommrp'] = billfinaldetails['totalfrommrp'];
        requestbody['totalafterindividualdiscount'] = billfinaldetails['totalafterindividualdiscount'];
        requestbody['totalfinaldecided'] = totalfinaldecided;
        requestbody['totalrecieved'] = totalrecieved;
        requestbody['problem'] = 'NA';
        requestbody['remarks'] = remarks;
        requestbody['taskdays'] = Number(taskDays);
        requestbody['taskmessage'] = taskMessage;
        if(requestbody['totalfinaldecided'] - requestbody['totalrecieved'] === 0)
        {
            requestbody['credit'] = false;
        }
        else
        {
            requestbody['credit'] = true;
        }

        setCustomername('');
        setCustomervillage('');
        customervillageref.current.value = '';
        setVillages(cvillages);
        setBillRows([]);
        setBillrowdetails([]);
        setTotalfinaldecided(0);
        setTotalrecieved(0);
        totalrecievedinputref.current.value = '';
        setBillfinaldetails({});
        setVilcustre([]);
        setRemarks('');
        setRemarksneeded(false);
        remarksref.current.value = '';
        setCheckedd(false);
        checkeddref.current.checked = false;
        if(['admin', 'owner'].includes(bupermissions))
        {
            setTaskDays(0);
            setTaskMessage('');
            taskdaysref.current.value = '';
            taskmessageref.current.value = '';
        }

        usHttp2(appvariables.apiurl+'bill/create/', 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => 
        {
            masterdispatcher(notificationsliceactions.updatenotification({'message': json.message, 'status': json.status}));
            setRemproducts(json.remproducts)
            masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
            if((totalfinaldecided - totalrecieved !== 0 || redirect) && json.billid)
            {
                navigate(`../getone/${json.billid}`);
            }
        });
    }

    useEffect(() => {
        if(customername !== '' && customervillage !== '')
        {
            var requestbody = {};
            requestbody['name'] = customername;
            requestbody['village'] = customervillage;
            try{
                usHttp2(appvariables.apiurl+'customer/getnumber/', 'POST', requestbody)
                .then((response) => response.json())
                .then((json) => {
                    setCustomernumber((json.pn).toString());
                    setCcredit(json.cc);
                })
                .catch((error) => {
                    setCustomernumber('');
                })
            }
            catch(error){
                setCustomernumber('');
            }
        }
        else
        {
            setCustomernumber('');
            setCcredit('');
        }
    },[customername, customervillage]);

    const cancelbill = () => {
        setCustomername('');
        setCustomervillage('');
        setVillages(cvillages);
        setBillRows([]);
        setBillrowdetails([]);
        setTotalfinaldecided(0);
        setTotalrecieved(0);
        totalrecievedinputref.current.value = '';
        setCheckedd(false);
        checkeddref.current.checked = false;
        customervillageref.current.value = '';
        setBillfinaldetails({});
        setVilcustre([]);
        setRemarks('');
        setRemarksneeded(false);
        remarksref.current.value = '';
        setCheckedd(false);
        checkeddref.current.checked = false;
        if(['admin', 'owner'].includes(bupermissions))
        {
            setTaskDays(0);
            setTaskMessage('');
            taskdaysref.current.value = '';
            taskmessageref.current.value = '';
        }
    }
    
    const [toggleadder, setToggleAdder ] = useState(false);

    const toggleraddertoogler = () => {
        setToggleAdder(toggleadder => !toggleadder);
    }

    const cancelstockaddhandler = () => {
        if(stock !== '')
        {
            setStock('');
            setName('');
            setPackagee('');
            setCompany('');
            setBatchid('');
            setPrice(0);
            setExpiryDate('');
            setStock('');
            setQuantity(0);
            setIndividualPrice(0);
            quantityref.current.value = "";
            individualpriceref.current.value = "";
        }
        toggleraddertoogler();
    }

    const [checkedd, setCheckedd] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const addproductsenabler = () => {
        return customername === '' || customervillage === '' || customernumber === '';
    }
    


    return(
        <div style={{overflow: toggleadder && 'hidden', height: toggleadder && '750px' }} >
            { toggleadder && <AddStocktobill submitdisabled={quantity < 1 || individualPrice > price || individualPrice == 0 || individualPrice === ''} bclickhandler={cancelstockaddhandler} stock={stock} tabledata={stocksdata.stocksdata} selectStock={selectStock} billrowdetails={billrowdetails} cfinputs={inputs} cfsubmithandler={addbillrowtolocal}/>  }

            {/* Bills Table */}
            <div style={{ display: toggleadder && 'none', backgroundColor: (totalfinaldecided - totalrecieved) > 0 && 'rgb(255 73 73)' }}>
                { remproducts !== undefined && remproducts.length !== 0 ? <div>
                    <Table data={remproducts} tablefields={
                        [
                            {'name': 'Name', 'key': 'name'},
                            {'name': 'Package', 'key': 'package'},
                            {'name': 'Company', 'key':'company'},
                            {'name': 'Quantity', 'key':'quan'}
                        ]
                    }  cs={{marginBottom: '20px'}} />
                    <hr></hr>
                </div> : <></> }
                <div style={{display: 'grid', alignItems: 'center' ,gridTemplateColumns: '1fr 1fr 1fr 1fr',width: '100%'}} >
                    <Dropdown refvar={customervillageref} dropdownstyle={'compact'} id={'createbillvill'} name={'createbillvill'} displayname={'Village'} changehandler={villagechangehandler} options={villages} />
                    <Dropdown refvar={customernameref} dropdownstyle={'compact'} id={'createbillcust'} name={'createbillcust'} displayname={'Customer Name'} changehandler={custnamechangehandler} options={vilcustre} />
                    <div style={{margin: '0 20px', fontSize: '12px', display: 'flex', gap: '10px'}} > {customernumber == "*******999" ? <div style={{fontSize: '16px', background: 'red', color: 'white'}} >Ask Number</div> : <div>Mobile: {customernumber}</div>} {ccredit !== '' && ccredit !== undefined && <div>Credit: {ccredit}</div>}</div>
                    <Button disabled={addproductsenabler()} clickhandler={toggleraddertoogler} cs={{backgroundColor: addproductsenabler() && 'rgba(0,0,0,0.6)', borderColor: addproductsenabler() && 'rgba(0,0,0,0.6)'}}>Add Product</Button>
                </div>
                <div style={{marginTop: '10px'}}>
                    <Button cs={{padding: '8px 16px'}} clickhandler={() => {setCustomername('Others');  customervillageref.current.value = 'Others';setCustomervillage('Others'); setTimeout(() => {customernameref.current.value= 'Others';},10)}} >Others</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '60px'}} >
                    <h1>Bill Details</h1>
                </div>
                <Table data={billrows} tablefields={tablefields2} cs={{marginTop: '20px'}}/>
                <Button clickhandler={cancelbill} cs={{ position: 'relative',  left: 'calc(100% - 150px)', marginTop: '10px' }} >Cancel Bill</Button>
                <Details data={billfinaldetailsc} />
                <CustomForm resetdisabled={true} submitdisabled={(remarksneeded && remarks.length < 25) || !checkedd || billrows.length === 0 || customername === '' || customervillage === '' || totalfinaldecided > billfinaldetails['totalafterindividualdiscount'] || totalfinaldecided < totalrecieved} inputboxstyle={'equal'} inputs={inputsforbilling} submitHandler={addbilltoserver}/>
            </div>
        </div>
    );
}

export default Createbill;