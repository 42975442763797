import { useState, useRef, useEffect } from "react";
import CustomForm from "../components/CustomForm";
import { useDispatch } from "react-redux";
import { budatasliceactions } from "../store/budata-slice";
import { useNavigate } from "react-router-dom";
import { notificationsliceactions } from "../store/notification";
import appvariables from "../appvariables";

const Loginpage = () => {
    const [logindata, setLogindata] = useState('');
    const logindataref = useRef();
    const [loggedin, setLoggedin] = useState(false);
    const [initial, setInitial] = useState(true);
    const navigate = useNavigate();

    const masterdispatcher = useDispatch();

    const reloadpage = () => {
        navigate('/');
        masterdispatcher(notificationsliceactions.updatenotification({message: 'Login Successful', status:'Success'}))
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

    if(!loggedin && initial)
    {
        setInitial(false);
        if(getCookie('cca').length)
        {
            setLoggedin(true);
        }
    }

    const logindatainputhandler = () => {
        var cca = logindataref.current.value;
        setLogindata(cca);
    }

    const addccadatatocookie = (e) => {
        e.preventDefault();

        document.cookie = "cca=" + logindata + `; expires=Thu, 31 Dec 2024 12:00:00 UTC; domain=${appvariables.url};path=/`;
        setLogindata('');
        masterdispatcher(budatasliceactions.updatebucca(getCookie('cca')));
        reloadpage();
    }

    const inputs = [
        {
            displayname: 'Login Data',
            name:'logindata',
            type: 'text',
            refvar: logindataref,
            changehandler: logindatainputhandler,
        }
    ]

    return(
        loggedin ?
        <div>Logged in as {getCookie('cca')}</div> :
        <CustomForm inputs={inputs} inputboxstyle={'normal'} submitHandler={addccadatatocookie}/>
    )
}

export default Loginpage;