import { useLoaderData } from "react-router-dom";
import Table from "../components/Table";
import React from "react";

const Allstocks = () => {
    const { stocks } = useLoaderData();

    
    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const tablefields = [
        {'name': 'Id', 'key': 'Id', cs: {width: '10px'}},
        {'name': 'Name', 'key':'Name', filter: 1},
        {'name': 'Package', 'key':'Package', filter: 1},
        {'name': 'Company', 'key':'Company', filter: 1},
        {'name': 'Batch Id', 'key':'BatchId', filter: 1},
        {'name': 'Quantity', 'key':'Quantity', filter: 1},
        {'name': 'Price', 'key':'Price', filter: 1},
        {'name': 'Expiry', 'key': 'ExpiryDate', transformer: TranformDate, filter: 1},
    ]

    return(
        <React.Fragment>
            <Table decider={'Ratea'} rowstyling={{ backgroundColor: "rgb(255 73 73)"}} data={stocks} tablefields={tablefields} />
        </React.Fragment>
    )
}

export default Allstocks;