import { Link, useNavigate } from "react-router-dom";
import classes from './homepage.module.css';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { usHttp } from "../../custom-hooks/use-http";
import appvariables from "../../appvariables";

const Producthomepage = (props) => {

    var productsectionlinks = useSelector(state => state.budata.sectionlinks.product);
    
    if(productsectionlinks === undefined)
    {
        productsectionlinks = [];
    }

    return(
        <div className={classes.homepage}>
            {productsectionlinks.map((productsectionlink, index)=> 
                <Link
                 key={'kkl'+index}
                 to={Object.keys(productsectionlink)[0]}>
                    {Object.values(productsectionlink)[0]}
                 </Link>
            )}
        </div>
    )
}

const loadAllProductsData = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'product/getall/','POST',{})
    if(responsedata != null)
    {
        return {
            'products': responsedata['products'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
}

const loadAllProductGroupsData = async () => {
    const responsedata = await usHttp(appvariables.apiurl + 'product/pggetall/', 'POST', {})
    if(responsedata != null)
    {
        return {
            'pgs': responsedata['productgroups'],
            'status': responsedata['status'],
            'message': responsedata['message']
        }
    }
}


const singlePGloader = async ({ params }) => {
    const responsedata = await usHttp(appvariables.apiurl+ 'product/productgroup/getone/', 'POST', { 'pgid': params.pgid })
    return {
        'pgdetails': responsedata['pgdetails'],
        'edit': responsedata['edit'],
    }
}

const editPGloader = async ({ params }) => {
    const responsedata = await usHttp(appvariables.apiurl+ 'product/productgroup/getone/', 'POST', { 'pgid': params.pgid });
    const responsedata2 = await usHttp(appvariables.apiurl+'product/getstrings/','POST',{})
    return {
        'pgdetails': responsedata['pgdetails'],
        'productstrings': responsedata2['productstrings'],
        'edit': responsedata['edit'],
    }
}

const loadProductLayout = async () => {
    const responsedata = await usHttp(appvariables.apiurl+'product/getcompanies/','POST',{})
    if(responsedata != null)
    {
        return {
            'companies': responsedata['companies'],
            'status': responsedata['status'],
            'message': responsedata['message']
        };
    }
}

export { loadAllProductsData, loadProductLayout, loadAllProductGroupsData, singlePGloader, editPGloader };

export default Producthomepage;