import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRouteError } from "react-router-dom"
import { redirectsliceactions } from "./store/redirect-slice";
import { useEffect } from "react";

const Errorboundary = () => {
    const location = useLocation();
    const masterdispatcher = useDispatch();
    const navigate = useNavigate();
    const redirect = useSelector(state => state.redirect);

    const error = useRouteError();

    useEffect(() => {
        if(redirect.init)
        {
            masterdispatcher(redirectsliceactions.updateredirectinit(false));
            masterdispatcher(redirectsliceactions.updateredirectto(location.pathname));
            navigate('');
        }
    },[]);
    
    return(
        redirect.to === '' ? <h2>Redirecting to Home</h2> : <h2>Access Denied</h2>
    )
}

export default Errorboundary;