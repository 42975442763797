import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import Table from "../components/Table";
import Details from "../components/Details";
import { useEffect, useRef, useState } from "react";
import appvariables from "../appvariables";
import { usHttp, usHttp2 } from "../custom-hooks/use-http";
import CustomForm from "../components/CustomForm";
import { useDispatch } from "react-redux";
import { notificationsliceactions } from "../store/notification";
import { loadingsliceactions } from "../store/loading-slice";

const generateStockDetailsJson = (sds) => {
    const stocks = []
    for(var i=0; i < sds.length; i++)
    {
        const kk = {};
        kk['name'] = sds[i].name;
        kk['package'] = sds[i].package;
        kk['company'] = sds[i].company;
        kk['batchid'] = sds[i].batchid;
        kk['price'] = sds[i].price;
        kk['expirydate'] = sds[i].expirydate;
        kk['quantity'] = sds[i].quantity;
        kk['individualpriceenteredbybuser'] = sds[i].individualpriceenteredbybuser;
        kk['rowkey'] = sds[i].rowkey;
        stocks.push(kk);
    }
    return stocks
}

const Editbill = (props) => {

    const { billdetails } = useLoaderData();
    const { billid } = useParams();
    const navigate = useNavigate();
    const masterdispatcher = useDispatch();

    const [billrows, setBillRows] = useState([]);
    const [returns, setReturns] = useState([]);
    const [billfinaldetails, setBillfinaldetails] = useState({});
    const [totalfinaldecided, setTotalfinaldecided] = useState(0);
    const [totalrecieved, setTotalrecieved] = useState(0);
    const [checkedd, setCheckedd] = useState(false);
    const totalfinaldecidedinputref = useRef();
    const totalrecievedinputref = useRef();
    const checkeddref = useRef();
    const stocksdetailsjson = generateStockDetailsJson(billdetails.stockdetails);

    useEffect(() => {
        var totalfrommrp = 0;
        var totalafterindidis = 0;
        for(var i=0; i< billrows.length; i++)
        {
            totalfrommrp = totalfrommrp + billrows[i].price * billrows[i].quantity;
            totalafterindidis = totalafterindidis + billrows[i]['individualpriceenteredbybuser'] * billrows[i].quantity;
        }
        setBillfinaldetails({
            'totalfrommrp': totalfrommrp,
            'totalafterindividualdiscount': totalafterindidis
        });
        var inputss = [...inputsforbilling];
        inputss[0].max = totalafterindidis;
        inputss[1].max = totalafterindidis;
        setInputsforbilling(inputss);
        totalfinaldecidedinputref.current.value = totalafterindidis;
        totalrecievedinputref.current.value = totalafterindidis;
        setTotalfinaldecided(totalafterindidis);
        setTotalrecieved(totalafterindidis);
    },[billrows]);

    useEffect(() => {
        setBillRows(stocksdetailsjson);
        let kk = {};
        kk['totalfrommrp'] = billdetails['totalfrommrp'];
        kk['totalafterindividualdiscount'] = billdetails['totalafterindividualdiscount'];
        setBillfinaldetails(kk);
        setTotalfinaldecided(billdetails['totalfinaldecided']);
        setTotalrecieved(billdetails['totalrecieved']);
        totalfinaldecidedinputref.current.value = billdetails['totalfinaldecided'];
        totalrecievedinputref.current.value = billdetails['totalrecieved'];
    },[]);

    const totalfinaldecidedchange = () => {
        setTotalfinaldecided(parseInt(totalfinaldecidedinputref.current.value));
        var inputss = [...inputsforbilling];
        inputss[1].max = totalfinaldecidedinputref.current.value;
        totalrecievedinputref.current.value = totalfinaldecidedinputref.current.value;
        setTotalrecieved(totalfinaldecidedinputref.current.value);
        setInputsforbilling(inputss);
    }

    const totalfinaldecidedblur = () => {
        if(totalfinaldecided > billfinaldetails['totalafterindividualdiscount'])
        {
            var kk = [...inputsforbilling];
            kk[0].error = 'Final Decided should be less than total from individual discounts';
            setInputsforbilling(kk);
        }
        else{
            var kk = [...inputsforbilling];
            kk[0].error = '';
            setInputsforbilling(kk);
        }
    }

    
    const totalrecievedchange = () => {
        setTotalrecieved(parseInt(totalrecievedinputref.current.value));
    }
    
    const totalrecievedblur = () => {
        if(totalrecieved > totalfinaldecided)
        {
            var kk = [...inputsforbilling];
            kk[1].error = 'Total Recieved should be less than Final Decided';
            setInputsforbilling(kk);
        }
        else{
            var kk = [...inputsforbilling];
            kk[1].error = '';
            setInputsforbilling(kk);
        }
    }
    
    useEffect(() => {
        var kk = [...inputsforbilling];
        kk[0].blurhandler = totalfinaldecidedblur;
        kk[1].blurhandler = totalrecievedblur;
        setInputsforbilling(kk);
    },[totalfinaldecided, totalrecieved, billfinaldetails]);

    const billfinaldetailsc = [
        {'name': 'Total From MRPs', 'value': billfinaldetails['totalfrommrp']},
        {'name': 'Total after Individual Discount', 'value': billfinaldetails['totalafterindividualdiscount']},
        {'name': 'Discount', 'value': billfinaldetails['totalfrommrp'] - totalfinaldecided }
    ]

    const [inputsforbilling, setInputsforbilling] = useState([
        {
            type: 'number',
            id: 'tftid',
            refvar: totalfinaldecidedinputref,
            changehandler:totalfinaldecidedchange,
            blurhandler: totalfinaldecidedblur,
            displayname: 'Total Final Decided',
            name: 'totalfinaldecided',
            max: 0,
            error: ''
        },
        {
            type: 'number',
            id: 'trid',
            refvar: totalrecievedinputref,
            changehandler: totalrecievedchange,
            blurhandler: totalrecievedblur,
            displayname: 'Total Recieved',
            name: 'totalrecieved',
            max: 0,
            error: ''
        },
        {
            type: 'checkbox',
            id: 'bcid',
            refvar: checkeddref,
            changehandler: (e) => setCheckedd(e.target.checked),
            displayname: 'Checked',
            name: 'checked',
        }
    ]);

    const updatebilltoserver = (e) => {
        e.preventDefault();
        masterdispatcher(loadingsliceactions.updateloading({state: 'loading'}));
        var requestbody = {};
        requestbody["billid"] = billid
        requestbody['stockdetailjson'] = billrows;
        requestbody['returns'] = returns;
        requestbody['totalfrommrp'] = billfinaldetails['totalfrommrp'];
        requestbody['totalafterindividualdiscount'] = billfinaldetails['totalafterindividualdiscount'];
        requestbody['totalfinaldecided'] = totalfinaldecided;
        requestbody['totalrecieved'] = totalrecieved;
        if(requestbody['totalfinaldecided'] - requestbody['totalrecieved'] === 0)
        {
            requestbody['credit'] = false;
        }
        else
        {
            requestbody['credit'] = true;
        }
        usHttp2(appvariables.apiurl+`bill/edit/`, 'POST', requestbody)
        .then((response) => response.json())
        .then((json) => {
            masterdispatcher(notificationsliceactions.updatenotification({message: json.message, status: json.status}));
            masterdispatcher(loadingsliceactions.updateloading({state: ''}));
            navigate(`../getone/${billid}`);
        })
    }

    const TranformDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4);
    }

    const [presentRowkey, setPresentRowkey] = useState('');

    const toggleForm = (rowkeyy) => {
        setPresentRowkey(rowkeyy)
        for(let i=0; i < billrows.length; i++)
        {
            if(billrows[i].rowkey === rowkeyy)
            {
                setQuantitymax(billrows[i].quantity);
            }
        }
    }

    const tablefields = [
        {'name': 'Name', 'key': 'name'},
        {'name': 'Package', 'key': 'package'},
        {'name': 'Company', 'key': 'company'},
        {'name': 'Expiry Date', 'key':'expirydate', transformer: TranformDate},
        {'name': 'Price', 'key': 'price'},
        {'name': 'Individual Price', 'key':'individualpriceenteredbybuser'},
        {'name': 'Quantity', 'key': 'quantity'},
        {'name': 'Update', 'key': 'rowkey',transformerr: toggleForm, text: 'Update'},
    ];

    const detdata = [
        {'name': 'Total From MRP', 'value': billdetails['totalfrommrp']},
        {'name': 'Total after Indi Discount', 'value': billdetails['totalafterindividualdiscount']},
        {'name': 'Total Final', 'value': billdetails['totalfinaldecided']},
        {'name': 'Discount', 'value': billdetails['totalfrommrp'] - billdetails['totalfinaldecided']},
        {'name': 'Credit', 'value': billdetails['totalfinaldecided'] - billdetails['totalrecieved']},
    ]

    const TranformBillDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        return date.substring(8,10) + ',' + (monthNames[Number(date.substring(5,7)) - 1]).substring(0,3) + ',' + date.substring(0,4) + ` (${date.substring(11,16)})`;
    }

    const updateBillRowDetails = (e) => {
        e.preventDefault();
        const temp_billrows = [...billrows];
        let kk = [...returns];
        var indprice = '';
        for(let i=0;i<stocksdetailsjson.length; i++)
        {
            if(stocksdetailsjson[i]['rowkey'] === presentRowkey)
            {
                indprice = stocksdetailsjson[i]['individualpriceenteredbybuser'];
            }
        }
        for(let i=0; i< temp_billrows.length; i++)
        {
            if(temp_billrows[i].rowkey === presentRowkey)
            {
                if(parseInt(quantity) === 0)
                {
                    kk.push({'sde': temp_billrows[i], 'quantity': temp_billrows[i]['quantity'], 'indprice': indprice});
                    temp_billrows.splice(i,1);
                    break;
                }
                kk.push({'sde': temp_billrows[i], 'quantity': temp_billrows[i].quantity - quantity, 'indprice': indprice});
                temp_billrows[i].quantity = quantity;
            }
        }
        setReturns(kk);
        setBillRows(temp_billrows);
        setQuantity('');
        setQuantitymax('');
        quantityref.current.value = '';
        setQuantityerror('');
        setPresentRowkey('');
    }

    const [quantity, setQuantity] = useState('');
    const [quantitymax, setQuantitymax] = useState('');
    const [quantityerror, setQuantityerror] = useState('');
    const quantityref = useRef();

    const quantityinputchange = (e) => {
        if(quantityref.current.value > quantitymax)
        {
            setQuantityerror(`Updated quantity should not be greater than ${quantitymax}`);
        }
        else if(quantityref.current.value < 0)
        {
            setQuantity(0);
            setQuantityerror('Value cannot be lessthan 0');
        }
        else
        {
            setQuantity(quantityref.current.value);
            setQuantityerror('');
        }
    }

    return (
        <>
        {presentRowkey !== '' && <CustomForm resetdisabled={true} inputboxstyle={'equal'} submitHandler={updateBillRowDetails} inputs={[{
            id: 'editbillquantity',
            displayname: 'New Quantity',
            name:'quantity',
            type: 'number',
            refvar: quantityref,
            min: 0,
            max: quantitymax,
            changehandler: quantityinputchange,
            error: quantityerror
        },]} />}
            <div style={{display: 'flex', height: '30px', flexDirection: 'row', marginLeft: '20px', alignItems: 'center'}} >
                <p style={{fontWeight: '600'}}>{billdetails['customername']}, &nbsp;</p>
                <p style={{fontWeight: '600'}}>{billdetails['customervillage']}</p>
            </div>
            <div style={{display: 'flex', height: '30px', flexDirection: 'row', justifyContent:'space-between', margin: '0px 20px', alignItems: 'center'}} >
                    <p style={{fontWeight: '600'}}>{TranformBillDate(billdetails['date'])}</p>
                <p>Billed by {billdetails['billedby']}</p>
            </div>
            <Table data={billrows} tablefields={tablefields} />
            <Details data={billfinaldetailsc} />
            <CustomForm submittext={'Update Bill'} resetdisabled={true} submitdisabled={!checkedd || totalfinaldecided > billfinaldetails['totalafterindividualdiscount'] || totalfinaldecided < totalrecieved} inputboxstyle={'equal'} inputs={inputsforbilling} submitHandler={updatebilltoserver}/>
        </>
    );
}

export default Editbill