import { createSlice } from "@reduxjs/toolkit";

const initvalue  = {
    'message': '',
    'state': ''
}

const loadingSlice = createSlice({
    name: 'loadingslice',
    initialState: initvalue,
    reducers: {
        updateloading(state, action)
        {
            state.state = action.payload.state;
        }
    }
});

export default loadingSlice.reducer;

export const loadingsliceactions = loadingSlice.actions;