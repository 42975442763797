function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

const usHttp = async (url, requestmethod, requestbody) => {
    const responsee = await fetch(url,{method: requestmethod, credentials: "include", body: JSON.stringify(requestbody)})
    const json = await responsee.json();
    return json;
}

const usHttp2 = async (url, requestmethod, requestbody) => {
  if(requestmethod === 'GET' )
  {
    return fetch(url,{method: requestmethod, credentials: "include"})
  }
  return fetch(url,{method: requestmethod, credentials: "include", body: JSON.stringify(requestbody)})
}

export {usHttp, usHttp2, getCookie};