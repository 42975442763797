import { useEffect, useState } from "react";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown"
import { usHttp2 } from "../custom-hooks/use-http";
import appvariables from "../appvariables";
import React from "react";
import Table from "../components/Table";
import { json } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadingsliceactions } from "../store/loading-slice";

const ProductAnalysis = () => {
    const [range, setRange] = useState("");
    const [tabledata, setTabledata] = useState([]);
    const [dateranges, setDateranges] = useState("");

    const rangechange = (e) => {
        setRange(e.target.value);
    }

    const [tablefields, setTablefields] = useState([
        {'name':'Name','filter':1,'key':'name'},
        {'name':'Package','filter':1,'key':'package'},
        {'name':'Company','filter':1, 'key': 'company'},
        {name: 'Present Quantity', key: 'presentquantity'},
        {'name': 'Total', key: 'total'},
      ]);

    
    useEffect(() => {
        const tt = [
            {'name':'Name','filter':1,'key':'name'},
            {'name':'Package','filter':1,'key':'package'},
            {'name':'Company','filter':1, 'key': 'company'},
            {name: 'Present Quantity', key: 'presentstock'},
            {'name': 'Total', key: 'total'},
          ];
        for(var i=0; i<dateranges.length; i++)
        {
            tt.push({'name': dateranges[i], 'key': dateranges[i]});
        }
        setTablefields(tt);
    },[dateranges]);

    const masterdispatcher = useDispatch();

    const getProductAnalysis = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        usHttp2(appvariables.apiurl + 'stock/getanalysis/', 'POST', {range: range})
        .then((response) => response.json())
        .then((json) => { setTabledata(json['products']); setDateranges(json['ranges']); masterdispatcher(loadingsliceactions.updateloading({'state': ''}));})
    }

    return(
        <React.Fragment>
            <div style={{display: 'flex', gap: '10px'}}>
                <Dropdown
                    dropdownstyle={"compact"}
                    options={[
                        "Last 1 Week",
                        "Last 2 Weeks",
                        "Last 4 Weeks",
                        "Last 1 Month",
                        "Last 2 Months",
                        "Last 4 Months",
                        "Last 6 Months",
                    ]}
                    id={"ds"}
                    name={"range"}
                    displayname={"Range"}
                    changehandler={rangechange}
                />
                <Button
                    clickhandler={getProductAnalysis}
                    disabled={range === ""}
                    cs={{
                    fontSize: "13px",
                    fontWeight: 400,
                    padding: "5px 10px",
                    backgroundColor: (range === "") && "rgba(0,0,0,0.6)",
                    borderColor: (range === "") && "rgba(0,0,0,0.6)",
                    }}
                 >Get Analysis</Button>
            </div>
            <Table
            data={tabledata}
            tablefields={tablefields}
            />
        </React.Fragment>
    )
}

export default ProductAnalysis;