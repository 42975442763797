import { Link } from "react-router-dom";
import classes from "./homepage.module.css";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ExpStock from "../customcomponents/ExpStock";
import Table from "../../components/Table";
import Followupsortasks from "../customcomponents/Followupsortasks";

const Homehomepage = () => {
  const budata = useSelector((state) => state.budata);
  var [billsectionlinks, setBillsectionlinks] = useState([]);

  const [impmessages, setImpmessages] = useState([]);

  const TranformDate = () => {
    let date = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      date.getDate() +
      "," +
      monthNames[Number(date.getMonth())] +
      "," +
      date.getFullYear()
    );
  };

  useEffect(() => {
    if (budata.cca !== "") {
      var kkhhp = budata.sectionlinks.home;
      if (kkhhp === undefined) {
        setBillsectionlinks([]);
      } else {
        setBillsectionlinks(kkhhp);
      }
    }
  }, [budata]);

  const updateImpmessagaes = (msg) => {
    let kk = [...impmessages];
    kk.push(msg);
    setImpmessages(kk);
  };

  return (
    <div className={classes.homepage} style={{ paddingTop: "10px" }}>
      {billsectionlinks.map((billsectionlink, index) => (
        <Link key={"kkl" + index} to={Object.keys(billsectionlink)[0]}>
          {Object.values(billsectionlink)[0]}
        </Link>
      ))}
      <div className={classes.homepagegrids}>
        <div className={classes.gridsection}>
          <p style={{ margin: "8px 0px" }}>
            Welcome to the Billing Site,{" "}
            <span style={{ fontWeight: 600, display: "inline-block" }}>
              {budata.name}
            </span>
          </p>
          <p>Today: {TranformDate()}</p>
          <ul>
            {impmessages.map((msg, index) => {
              return <li key={"sdgtf" + index}>{msg}</li>;
            })}
          </ul>
        </div>
        <div className={classes.gridsection}>
          {(budata.permissions === "owner" ||
            budata.permissions === "admin") && (
            <React.Fragment>Summary Section</React.Fragment>
          )}
        </div>
        <div className={classes.gridsection}>
          {(budata.permissions === "owner" ||
            budata.permissions === "admin") && (
            <Followupsortasks msgupdater={updateImpmessagaes} />
          )}
        </div>
        <div className={classes.gridsection}>
          {(budata.permissions === "owner" ||
            budata.permissions === "admin") && (
            <ExpStock msgupdater={updateImpmessagaes} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Homehomepage;
